import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';

// ----------------------------------------------------------------------

type ContactList = {
  id: number;
  list_name: string;
  filtered_contacts: any[];
  contacts_count: number;
};

interface ContactsState {
  contactLists: ContactList[];
  hasError: boolean;
  errorMessage: string;
  selectedList: ContactList | null;
  multipleListSelect: ContactList[];
  isListEditorModalOpen: boolean;
  isListSelectorModalOpen: boolean;
  isRemoveContactsModalOpen: boolean;
  addingToList: boolean;
  checkedIDs: number[];
  contactsPageSelected: boolean;
  allContactsSelected: boolean;
  tableUpdator: boolean;
}

const initialState: ContactsState = {
  contactLists: [],
  hasError: false,
  errorMessage: '',
  selectedList: null,
  multipleListSelect: [],
  isListEditorModalOpen: false,
  isListSelectorModalOpen: false,
  isRemoveContactsModalOpen: false,
  addingToList: false,
  checkedIDs: [],
  contactsPageSelected: false,
  allContactsSelected: false,
  tableUpdator: false
};

const slice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    // set contact lists
    setContactLists(state: any, action) {
      state.contactLists = action.payload;
    },
    setSelectedList(state: any, action) {
      state.selectedList = action.payload;
      state.multipleListSelect = [action.payload];
    },
    setCheckedIDs(state: any, action) {
      state.checkedIDs = action.payload;
    },
    setContactsPageSelected(state: any, action) {
      state.contactsPageSelected = action.payload;
    },
    setAllContactsSelected(state: any, action) {
      state.allContactsSelected = action.payload;
    },
    setMultipleListSelect(state: any, action) {
      state.multipleListSelect = action.payload;
    },

    // Moda Open/Close
    openEdiotorModal(state) {
      state.isListEditorModalOpen = true;
    },
    closeEditorModal(state) {
      state.isListEditorModalOpen = false;
    },
    openSelectorModal(state) {
      state.isListSelectorModalOpen = true;
    },
    closeSelectorModal(state) {
      state.isListSelectorModalOpen = false;
    },
    setRemoveContactsModal(state, action) {
      state.isRemoveContactsModalOpen = action.payload;
    },
    setAddingToList(state, action) {
      state.addingToList = action.payload;
    },
    setTableUpdator(state, action) {
      state.tableUpdator = action.payload;
    },

    // ErrorsselectedList
    hasError(state, action) {
      state.hasError = true;
      state.errorMessage = action.payload.message;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  openEdiotorModal,
  closeEditorModal,
  setCheckedIDs,
  openSelectorModal,
  closeSelectorModal,
  setSelectedList,
  setContactLists,
  setAddingToList,
  setContactsPageSelected,
  setAllContactsSelected,
  setTableUpdator,
  setMultipleListSelect,
  setRemoveContactsModal
} = slice.actions;

// ----------------------------------------------------------------------

export function getContactLists(customerId?: any) {
  return async (dispatch: any) => {
    try {
      const url = 'contacts/contact-list/';
      const response = await axiosInstance.get(url);
      dispatch(slice.actions.setContactLists(response.data.contact_lists));
      dispatch(slice.actions.setSelectedList(response.data.contact_lists[0]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addContactsToList(customerId?: any) {
  return async (dispatch: any, getState: any) => {
    // Make the request to add contacts to the list
    const {
      checkedIDs,
      multipleListSelect,
      contactLists,
      selectedList
    } = getState().contacts;
    try {
      const selectedIDs = multipleListSelect.map((list: any) => list.id);
      let url;
      if (customerId) {
        url = `contacts/update-lists-contacts/?pk=${customerId}`;
      } else {
        url = 'contacts/update-lists-contacts/';
      }
      const response = await axiosInstance.post(url, {
        contact_ids: checkedIDs,
        list_ids: selectedIDs
      });
      const { ok, contact_lists } = await response.data;
      const selectedListIndex = contactLists.findIndex(
        (list: any) => list.id === selectedList.id
      );

      // If the request was successful, update the redux state to it's default
      if (ok) {
        dispatch(setContactLists(contact_lists));
        dispatch(setSelectedList(contact_lists[selectedListIndex]));
        dispatch(setCheckedIDs([]));
        dispatch(setAddingToList(false));
        dispatch(closeSelectorModal());
        dispatch(setAllContactsSelected(false));
        dispatch(setContactsPageSelected(false));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      console.error('Error updating contact list:', error);
    }
  };
}

// ----------------------------------------------------------------------

export function removeContactsFromList(customerId?: any) {
  return async (dispatch: any, getState: any) => {
    // Make the request to remove contacts from the list
    const { checkedIDs, selectedList, contactLists } = getState().contacts;
    try {
      let url;
      if (customerId) {
        url = `contacts/update-lists-contacts/?pk=${customerId}`;
      } else {
        url = 'contacts/update-lists-contacts/';
      }
      const response = await axiosInstance.put(url, {
        contact_ids: checkedIDs,
        contact_list_id: selectedList.id
      });
      const { ok, contact_lists } = await response.data;
      const selectedListIndex = contactLists.findIndex(
        (list: any) => list.id === selectedList.id
      );
      // If the request was successful, update the redux state to it's default
      if (ok) {
        dispatch(setContactLists(contact_lists));
        dispatch(setSelectedList(contact_lists[selectedListIndex]));
        dispatch(setCheckedIDs([]));
        dispatch(setAddingToList(false));
        dispatch(closeSelectorModal());
        dispatch(setAllContactsSelected(false));
        dispatch(setContactsPageSelected(false));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      console.error('Error updating contact list:', error);
    }
  };
}
