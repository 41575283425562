import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import WizardContext from './WizardContext';

type Props = {
  onNext: any;
  onBack: any;
  disabled: boolean;
  noBack: boolean;
  onFetch: any;
  currentIndex?: any;
};
const NextButton = ({
  onBack,
  onNext,
  disabled,
  noBack,
  onFetch,
  currentIndex
}: Props) => {
  const context = useContext<any>(WizardContext);
  const { createAgentID } = context;
  const showSkipped = currentIndex === 1 && !createAgentID;

  return (
    <>
      <Box display="flex" justifyContent="center" margin="0px 1.25rem">
        {context.saving ? (
          <CircularProgress />
        ) : disabled ? (
          <Typography variant="subtitle2" color="error">
            Please resolve the errors above
          </Typography>
        ) : (
          ''
        )}
      </Box>
      {!noBack && !createAgentID && (
        <Button
          id="backButton"
          style={{ marginRight: '1.25rem' }}
          // startIcon={<ArrowBackIosIcon />}
          onClick={onBack}
          variant="outlined"
        >
          {'< Back'}
        </Button>
      )}

      {!createAgentID && (
        <Button
          id="nextButton"
          // endIcon={<ArrowForwardIosIcon />}
          disabled={disabled}
          onClick={onNext}
          variant="contained"
        >
          {/* {currentIndex === 2 ? 'Finish >' : showSkipped ? 'Skip >' : 'Next >'} */}
          {'Continue >'}
        </Button>
      )}
    </>
  );
};

export default NextButton;
