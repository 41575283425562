import {
  DefaultPrivacyLevel,
  RumInitConfiguration
} from '@datadog/browser-rum';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const cloudinaryConfig = {
  cloudinaryKey: process.env.REACT_APP_CLOUDINARY_KEY,
  cloudinaryPreset: process.env.REACT_APP_CLOUDINARY_PRESET,
  cloudinaryUrl: process.env.REACT_APP_CLOUDINARY_URL
};

export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;
export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

export const datadogConfig: RumInitConfiguration = {
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID!,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN!,
  site: 'us5.datadoghq.com',
  service: 'hub-controlcenter',
  env: process.env.REACT_APP_DATADOG_ENVIRONMENT,
  sessionSampleRate: parseInt(process.env.REACT_APP_DATADOG_SAMPLE_RATE!, 10),
  sessionReplaySampleRate: parseInt(
    process.env.REACT_APP_DATADOG_SAMPLE_RATE!,
    10
  ),
  trackUserInteractions:
    process.env.REACT_APP_DATADOG_TRACK_USER_INTERACTIONS === 'true',
  trackLongTasks:
    process.env.REACT_APP_DATADOG_TRACK_USER_INTERACTIONS === 'true',
  trackResources:
    process.env.REACT_APP_DATADOG_TRACK_USER_INTERACTIONS === 'true',
  defaultPrivacyLevel: 'allow' as DefaultPrivacyLevel
};
