import { lazy } from 'react';
import * as Sentry from '@sentry/react';

export const lazyWithRetry = (componentImport: any) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed-for-cache') ||
        'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem(
        'page-has-been-force-refreshed-for-cache',
        'false'
      );

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem(
          'page-has-been-force-refreshed-for-cache',
          'true'
        );
        /*
          We can not return a value that is not an object whose .default property is a valid react component type
          https://react.dev/reference/react/lazy#load
        */
        // return window.location.reload();
        window.location.reload();
        // Since we will be reloading anyway let's just return an empty component
        // Need to return an import, though, as that has the .default
        return await import('../components/Empty');
      }

      Sentry.setContext('Error Context', {
        Message: 'Error captured in lazyWithRetry',
        Error: error,
        componentImport: componentImport?.toString() ?? null
      });
      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      Sentry.captureException(error);
      throw error;
    }
  });
