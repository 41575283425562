import { ReactNode, useEffect, useState, useRef } from 'react';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
// sentry
import * as Sentry from '@sentry/react';
//

import HomeIcon from '@material-ui/icons/Home';
import CalendarToday from '@material-ui/icons/CalendarToday';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import BarChartIcon from '@material-ui/icons/BarChart';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ConstructionIcon from '@material-ui/icons/Construction';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { useSelector, useDispatch } from 'react-redux';
import axios from '../../utils/axios';
import { PATH_AGENTS } from '../../routes/paths';
import DashboardNavbar from '../../components/SubNav';
import useAuth from '../../hooks/useAuth';
import getSSOLink from '../../utils/sso';
import { RootState } from '../../redux/store';
import {
  getActionItemsLink,
  getDisableExternalActionItemLink
} from '../../redux/slices/displayedUser';

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: '100%',
  overflow: 'hidden',
  paddingTop: '0px',
  marginTop: '0px'
}));

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingBottom: theme.spacing(10),
  paddingTop: '0px'
}));

type StaffLayoutProps = {
  children?: ReactNode;
};

export default function StaffLayout({ children }: StaffLayoutProps) {
  const { displayedUserDetails } = useAuth();
  const firstUpdate = useRef(true);
  const dispatch = useDispatch();
  const { initialTimeActionItemClick, triggerAPICall } = useSelector(
    (state: RootState) => state.user
  );
  const [ssoLink, setSSOLink] = useState('');
  const [oldRunLink, setOldRunLink] = useState<string>('');
  const [newRunLink, setNewRunLink] = useState<string>('');
  const [maxApiCalls, setMaxApiCalls] = useState<number>(100);
  const [links, setLinks] = useState<any>([]);

  // Set custom sentry tags
  Sentry.setTag('layout', 'agentboard');

  useEffect(() => {
    if (displayedUserDetails) {
      getSSOLink(
        displayedUserDetails.infusionsoft_id,
        displayedUserDetails?.email,
        'dashboard'
      ).then((link) => setSSOLink(link));
    }
  }, [displayedUserDetails]);

  // on the first render or if there's a change to backend link,
  // then check for a run link or send the process st link
  useEffect(() => {
    if (displayedUserDetails && displayedUserDetails.monthly_action_items) {
      // check if the user link has 'run-link' or not
      const testLinkForRunLink = displayedUserDetails.monthly_action_items.match(
        'run-link'
      );
      // if they do, then append user data to that link
      if (testLinkForRunLink && testLinkForRunLink.index) {
        setOldRunLink(displayedUserDetails.monthly_action_items);
        // remove anything after 'run-link' based on RegEx index
        const runLinkSubstring = displayedUserDetails.monthly_action_items.substring(
          0,
          testLinkForRunLink.index + 8
        );
        // add the user data required by process street
        const newDate = new Date();
        const currentMonth = newDate.toLocaleString('default', {
          month: 'long'
        });
        const currentYear = newDate.getFullYear();
        const encodedEmail = encodeURIComponent(displayedUserDetails.email);
        const runLinkWithUser = `${runLinkSubstring}?checklist_name=${displayedUserDetails.first_name}+${displayedUserDetails.last_name}+${currentMonth}+${currentYear}&form.Account_Email=${encodedEmail}`;
        dispatch(getActionItemsLink(runLinkWithUser));
        // if they don't, then just use the updated link
      } else {
        dispatch(getDisableExternalActionItemLink(false));
        dispatch(getActionItemsLink(displayedUserDetails.monthly_action_items));
      }
    }
  }, [displayedUserDetails?.monthly_action_items]);

  // artifical sleep function to delay API calls
  function sleep(ms: any) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  // wait 8 sec, make the API, set the new link to state, then change runAPIcall
  // to see if the new link is the same as the old link
  const delayedAPICall = async () => {
    if (
      maxApiCalls > 0 &&
      Math.round(Date.now() / 1000) - initialTimeActionItemClick < 600
    ) {
      try {
        await sleep(10000);
        const response = await axios.get('/customer/profile/me/');
        setNewRunLink(response.data.monthly_action_items);
        // await sleep(2000);
        setMaxApiCalls(maxApiCalls - 1);
      } catch (error) {
        // console.log(error);
      }
    }
  };
  useEffect(() => {
    // don't run the API call loop on first render and make sure
    // it only runs on the 1-Min Video card
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    // see if the old link and new link are the same, if so, run the api call
    // if not, stop and change the button
    if (oldRunLink !== newRunLink && newRunLink !== '') {
      // dispatch the new link to redux, so it's available for the nav bars
      dispatch(getActionItemsLink(newRunLink));
      // re-activate the button and update the link
      dispatch(getDisableExternalActionItemLink(false));
    } else {
      // else keep looping API calls
      dispatch(getDisableExternalActionItemLink(true));
      dispatch(getActionItemsLink('#'));
      delayedAPICall();
    }
  }, [maxApiCalls, triggerAPICall]);

  const overview_link = {
    name: 'Home',
    route: PATH_AGENTS.root,
    icon: HomeIcon,
    external: false
  };
  const tools_link = {
    name: 'Tools & Resources',
    route: PATH_AGENTS.tools,
    icon: ConstructionIcon,
    external: false
  };
  const club_classic_link = {
    name: 'Club Classic',
    route: ssoLink,
    icon: DashboardIcon,
    external: true
  };
  const help_link = {
    name: 'Help',
    route: 'https://thepaperlessagent.freshdesk.com/support/solutions',
    icon: HelpOutlineIcon,
    external: true
  };
  const campaignsLink = {
    name: 'Campaigns',
    route: PATH_AGENTS.allCampaigns,
    icon: CalendarToday,
    external: false
  };
  const contacts_link = {
    name: 'Contacts',
    route: PATH_AGENTS.contacts,
    icon: PersonOutlineIcon,
    external: false
  };
  const hotsheet_link = {
    name: 'Hotsheet',
    route: PATH_AGENTS.hotsheet,
    icon: BarChartIcon,
    external: false
  };

  useEffect(() => {
    if (displayedUserDetails) {
      const featureLinks: any = [];

      featureLinks.push(campaignsLink);
      if (displayedUserDetails?.feature_flag_contacts) {
        featureLinks.push(contacts_link);
      }
      if (displayedUserDetails?.feature_flag_hotsheet) {
        featureLinks.push(hotsheet_link);
      }
      /*
        If we combine to Leads again uncomment this
      if (
        displayedUserDetails?.feature_flag_contacts ||
        displayedUserDetails?.feature_flag_hotsheet ||
        displayedUserDetails?.secondary_information?.hotsheet_most_recent_report
      ) {
        featureLinks.push(leads_link);
      }
      */

      setLinks([
        overview_link,
        ...featureLinks,
        tools_link,
        club_classic_link,
        help_link
      ]);
    }
  }, [displayedUserDetails, ssoLink]);
  return (
    <RootStyle>
      <DashboardNavbar links={links} />
      <MainStyle>{children}</MainStyle>
    </RootStyle>
  );
}

// *********
// OLD LINKS
// *********

/*
  const leads_link = {
    name: 'Leads',
    route: displayedUserDetails?.feature_flag_hotsheet
      ? PATH_AGENTS.hotsheet
      : displayedUserDetails?.feature_flag_contacts
      ? PATH_AGENTS.contacts
      : displayedUserDetails?.secondary_information?.hotsheet_most_recent_report
      ? displayedUserDetails?.secondary_information?.hotsheet_most_recent_report
      : '',
    icon: PersonOutlineIcon,
    external: false
  };
  const resource_link = {
    name: 'Resources',
    route: PATH_AGENTS.resources,
    icon: ImageIcon,
    external: false
  };
  const trainingLink = {
      name: 'Training',
      route: trainingSSOLink,
      icon: SchoolIcon,
      external: true
    };
  const action_link = {
    name: useMediaQuery('(min-width:67rem)') ? '1-Min Video' : 'Video',
    route: actionItemsLink,
    icon: AssignmentIcon,
    external: true
  };
  const hotsheet_report_link = {
    name: 'Hotsheet',
    route: displayedUserDetails?.secondary_information
      ?.hotsheet_most_recent_report
      ? displayedUserDetails.secondary_information?.hotsheet_most_recent_report
      : '',
    icon: BarChartIcon,
    external: true
  };

  const posts_link = {
    name: 'Posts',
    route: PATH_AGENTS.posts,
    icon: PanoramaIcon,
    external: false
  };
*/
