import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Typography, Link } from '@material-ui/core';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

type LinkList = {
  route: string;
  external?: boolean;
  name: string;
  icon?: any;
};
type DashboardNavbarProps = {
  links: LinkList[];
  compact?: boolean;
  primaryNav: boolean;
};

export default function DashboardNavbar({
  links,
  compact,
  primaryNav
}: DashboardNavbarProps) {
  const loc = useLocation();
  const { displayedUserDetails } = useAuth();
  const userId = displayedUserDetails?.id
    ? displayedUserDetails.id.toString()
    : '0';

  const LinkStyle = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center'
  }));

  const RootStyle = styled(Box)(({ theme }) => ({
    borderTop: 'dashed 3px rgb(229, 232, 235)',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: '4.5rem',
    p: {
      padding: '0.625rem 0px'
      //  margin: '0px 10px'
    },
    svg: {
      color: theme.palette.text.secondary
    },
    'p, svg': {
      '&.selected': {
        color: theme.palette.text.primary,
        '&:hover': {
          color: theme.palette.text.primary
        }
      },
      '&:hover': {
        color: theme.palette.text.secondary
      }
    },
    'a:not(:first-child)': {
      margin: '0px 1.25rem'
    },
    'a:first-child': {
      marginRight: '1.25rem'
    },
    a: {
      '&.selected': {
        borderBottom: `${theme.palette.primary.main} solid 0.1875rem`
      },
      '&:hover': {
        textDecoration: 'none'
      }
    }
  }));

  const setSelected = (link: LinkList) => {
    let isSelected = '';
    const pathName = loc.pathname.split('/').slice(-1)[0];
    const linkRoute = link.route.split('/').slice(-1)[0];
    isSelected = linkRoute === pathName ? 'selected' : '';

    if (
      (pathName === 'your-campaigns' || pathName === 'posts') &&
      linkRoute === 'browse-campaigns' &&
      primaryNav
    ) {
      isSelected = 'selected';
    } /*
    If we combine to Leads again uncomment this

    else if (
      pathName === 'contacts' &&
      linkRoute === 'hotsheet' &&
      primaryNav
    ) {
      isSelected = 'selected';
    }
    */

    return isSelected;
  };

  return (
    <RootStyle>
      <Box display="flex" justifyContent="flex-start" width="100%">
        {links.map((link) =>
          link.route ? (
            <Link
              to={
                link.external
                  ? {
                      pathname: link.route
                    }
                  : link.route.replace(':userId', userId)
              }
              className={setSelected(link)}
              key={link.name}
              component={RouterLink}
              target={link.external ? '_blank' : undefined}
            >
              <LinkStyle>
                {link.icon && (
                  <link.icon
                    className={link.route === loc.pathname ? 'selected' : ''}
                  />
                )}
                <Typography
                  variant="body1"
                  className={link.route === loc.pathname ? 'selected' : ''}
                  color="GrayText"
                >
                  {link.name}
                </Typography>
              </LinkStyle>
            </Link>
          ) : (
            ''
          )
        )}
      </Box>
    </RootStyle>
  );
}
