// mock api
// import './_apis_';

// i18n
import './locales/i18n';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// lightbox
import 'react-image-lightbox/style.css';

// editor
import 'react-quill/dist/quill.snow.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

// Trying to prevent Resize observer loop
import './App.css';

// sentry error tracking
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

// utils

// ----------------------------------------------------------------------

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  ignoreErrors: ['ResizeObserver loop limit exceeded', 'Network Error'],
  // Userfeedback widget
  beforeSend(event, hint) {
    // If user is a customer and there in an exception, show the report dialog
    if (hint?.originalException) {
      const new_hint: any = hint.originalException;
      if (typeof new_hint != 'string') {
        const new_hint_keys = Object.keys(new_hint);
        if (
          new_hint_keys.includes('detail') &&
          new_hint_keys.includes('title')
        ) {
          if (event?.exception?.values) {
            event.exception.values[0] = {
              type: new_hint.title,
              value: new_hint.detail
            };
          }
        } else if (new_hint_keys?.includes('response')) {
          try {
            const new_response_keys = Object.keys(new_hint.response);
            if (new_response_keys.includes('data')) {
              const new_data_keys = Object.keys(new_hint.response.data);
              if (
                new_data_keys.includes('title') &&
                new_data_keys.includes('detail')
              ) {
                const new_data = new_hint.response.data;
                if (event?.exception?.values) {
                  event.exception.values[0] = {
                    type: new_data.title,
                    value: new_data.detail
                  };
                }
              }
            }
          } catch (err) {
            console.log(err);
          }
        }
      }
    }
    if (event?.tags?.raw_message) {
      event.tags.raw_message = event.message;
    } else {
      event.tags = { raw_message: event.message };
    }

    try {
      const numOfRefreshesString = localStorage.getItem('numOfRefreshes');
      if (typeof numOfRefreshesString === 'string') {
        const numOfRefreshesInt = Number(numOfRefreshesString);
        if (numOfRefreshesInt < 1) {
          localStorage.setItem(
            'numOfRefreshes',
            (numOfRefreshesInt + 1).toString()
          );
          window.location.replace(window.location.pathname);
        }
      }
    } catch (err) {
      console.log(err);
    }
    return event;
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
