import jwtDecode from 'jwt-decode';
import axios from '../../utils/axios';
import handleLogoutRedirect from '../../utils/handleExpiredRefreshToken';
import { setAuthTokens } from './authJwt';
import { store } from '../store';
// ----------------------------------------------------------------------

const isValidToken = async (accessToken: string, refreshToken: string) => {
  // Check if access token is expired
  if (!accessToken) {
    return false;
  }
  const currentTime = Date.now() / 1000 + 30;

  try {
    const decoded = jwtDecode<{ exp: number }>(accessToken);

    // If not expired return true
    if (decoded.exp > currentTime) {
      return true;
    }
  } catch (err) {
    console.log(err);
  }

  try {
    // Check if refresh token is valid
    const refreshDecoded = jwtDecode<{ exp: number }>(refreshToken);
    if (refreshDecoded.exp < currentTime) {
      return false;
    }
  } catch (err) {
    return false;
  }

  // If valid use refresh token to get new access token
  try {
    const result = await refreshAccessToken(refreshToken);
    return result;
  } catch (err) {
    return false;
  }
};

const setSession = async (
  accessToken: string | null,
  refreshToken?: string
) => {
  const { dispatch } = store;
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken);
      await dispatch(setAuthTokens(accessToken, refreshToken));
    }
    const refreshes = localStorage.getItem('numOfRefreshes');
    if (!refreshes) {
      localStorage.setItem('numOfRefreshes', '0');
    }
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    delete axios.defaults.headers.common.Authorization;

    localStorage.removeItem('alreadyErrorAlerted');
    localStorage.removeItem('numOfRefreshes');
  }
};
// ----------------------------------------------------------------------

async function refreshAccessToken(refreshToken: string) {
  const { dispatch } = store;
  const response = await axios.post('/api/token/refresh/', {
    refresh: refreshToken
  });
  if (response.data.access) {
    const accessToken = response.data.access;
    const newRefreshToken = response.data.refresh;
    setSession(accessToken, newRefreshToken);
    return true;
  }
  return false;
}

async function checkRefreshToken() {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  if (!isValidToken(accessToken!, refreshToken!)) {
    const isRefreshed = await refreshAccessToken(
      localStorage.getItem('refreshToken') || ''
    );
    if (!isRefreshed) {
      handleLogoutRedirect();
    }
  }
}

export {
  isValidToken,
  setSession,
  refreshAccessToken as refreshToken,
  checkRefreshToken
};
