import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

export default function SvgIcon(theme: Theme) {
  return {
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          width: '1.25rem',
          height: '1.25rem',
          fontSize: 'inherit'
        },
        fontSizeLarge: {
          width: '2rem',
          height: '2rem',
          fontSize: 'inherit'
        }
      }
    }
  };
}
