import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

type CampaignManagerState = {
  facebookUSCaption: string;
  facebookCanadaCaption: string;
  instagramUSCaption: string;
  instagramCanadaCaption: string;
  emailCanadaCaption: string;
  emailUSCaption: string;
  blogCanadaCaption: string;
  blogUSCaption: string;
  facebookUSTags: any;
  facebookCanadaTags: any;
  instagramUSTags: any;
  instagramCanadaTags: any;
  emailCanadaTags: any;
  emailUSTags: any;
  blogCanadaTags: any;
  blogUSTags: any;
  sendToFacebook: any;
  sendToInstagram: any;
  sendToUSCustomers: any;
  sendToCanadaCustomers: any;
  scheduledFor: string;
  type: string;
  resourceFileType: string;
  title: string;
  instructions: string;
  resourceDescription: string;
  referenceName: string;
  customizationURL: string;
  contentIDRedux: string;
  campaignIDRedux: string;
  persistentStateNewContetnt: boolean;
  persistentStateEditContetnt: boolean;
  persistentStateCloneContetnt: boolean;
  needUpdatePreviewAfterRefresh: boolean;
  postRecordID: string;
  celeryTaskID: string;
  celeryOrPostRecord: string;
  fileURLs: any;
  videoURLs: any;
  thumbnailURLs: any;
  thumbnailMilliseconds: string;
  showUpdatePreview: any;
  showMarketingSavedToast: boolean;
};
const initialCampaignManagerState: CampaignManagerState = {
  facebookUSCaption: '',
  facebookCanadaCaption: '',
  instagramUSCaption: '',
  instagramCanadaCaption: '',
  emailCanadaCaption: '',
  emailUSCaption: '',
  blogCanadaCaption: '',
  blogUSCaption: '',
  facebookUSTags: { tags: [] },
  facebookCanadaTags: { tags: [] },
  instagramUSTags: { tags: [] },
  instagramCanadaTags: { tags: [] },
  emailUSTags: { tags: [] },
  emailCanadaTags: { tags: [] },
  blogUSTags: { tags: [] },
  blogCanadaTags: { tags: [] },
  sendToFacebook: null,
  sendToInstagram: null,
  sendToUSCustomers: null,
  sendToCanadaCustomers: null,
  scheduledFor: '',
  type: '',
  resourceFileType: '',
  title: '',
  instructions: '',
  resourceDescription: '',
  referenceName: '',
  customizationURL: '',
  contentIDRedux: '',
  campaignIDRedux: '',
  persistentStateNewContetnt: false,
  persistentStateEditContetnt: false,
  persistentStateCloneContetnt: false,
  needUpdatePreviewAfterRefresh: false,
  postRecordID: '-1',
  celeryTaskID: '',
  celeryOrPostRecord: 'postRecord',
  fileURLs: [],
  videoURLs: [],
  thumbnailURLs: [],
  thumbnailMilliseconds: '',
  showUpdatePreview: false,
  showMarketingSavedToast: false
};

const slice = createSlice({
  name: 'campaignManager',
  initialState: initialCampaignManagerState,
  reducers: {
    getFacebookUSCaptionSuccess(state, action) {
      state.facebookUSCaption = action.payload;
    },
    getFacebookCanadaCaptionSuccess(state, action) {
      state.facebookCanadaCaption = action.payload;
    },
    getInstagramUSCaptionSuccess(state, action) {
      state.instagramUSCaption = action.payload;
    },
    getInstagramCanadaCaptionSuccess(state, action) {
      state.instagramCanadaCaption = action.payload;
    },
    getEmailCanadaCaptionSuccess(state, action) {
      state.emailCanadaCaption = action.payload;
    },
    getEmailUSCaptionSuccess(state, action) {
      state.emailUSCaption = action.payload;
    },
    getBlogCanadaCaptionSuccess(state, action) {
      state.blogCanadaCaption = action.payload;
    },
    getBlogUSCaptionSuccess(state, action) {
      state.blogUSCaption = action.payload;
    },
    getFacebookUSTagsSuccess(state, action) {
      state.facebookUSTags = action.payload;
    },
    getFacebookCanadaTagsSuccess(state, action) {
      state.facebookCanadaTags = action.payload;
    },
    getInstagramUSTagsSuccess(state, action) {
      state.instagramUSTags = action.payload;
    },
    getInstagramCanadaTagsSuccess(state, action) {
      state.instagramCanadaTags = action.payload;
    },
    getEmailUSTagsSuccess(state, action) {
      state.emailUSTags = action.payload;
    },
    getResourceTypeSuccess(state, action) {
      state.resourceFileType = action.payload;
    },
    getEmailCanadaTagsSuccess(state, action) {
      state.emailCanadaTags = action.payload;
    },
    getBlogUSTagsSuccess(state, action) {
      state.blogUSTags = action.payload;
    },
    getBlogCanadaTagsSuccess(state, action) {
      state.blogCanadaTags = action.payload;
    },
    getSendToFacebookSuccess(state, action) {
      state.sendToFacebook = action.payload;
    },
    getSendToUSCustomersSuccess(state, action) {
      state.sendToUSCustomers = action.payload;
    },
    getSendToInstagramSuccess(state, action) {
      state.sendToInstagram = action.payload;
    },
    getSendToCanadaCustomersSuccess(state, action) {
      state.sendToCanadaCustomers = action.payload;
    },
    getScheduledForSuccess(state, action) {
      state.scheduledFor = action.payload;
    },
    getTypeSuccess(state, action) {
      state.type = action.payload;
    },
    getTitleSuccess(state, action) {
      state.title = action.payload;
    },
    getInstructionsSuccess(state, action) {
      state.instructions = action.payload;
    },
    getDescriptionSuccess(state, action) {
      state.resourceDescription = action.payload;
    },
    getReferenceNameSuccess(state, action) {
      state.referenceName = action.payload;
    },
    getCustomizationURLSuccess(state, action) {
      state.customizationURL = action.payload;
    },
    getContentIDSuccess(state, action) {
      state.contentIDRedux = action.payload;
    },
    getCampaignIDSuccess(state, action) {
      state.campaignIDRedux = action.payload;
    },
    getPersistentStateNewContentSuccess(state, action) {
      state.persistentStateNewContetnt = action.payload;
    },
    getPersistentStateEditContentSuccess(state, action) {
      state.persistentStateEditContetnt = action.payload;
    },
    getPersistentStateCloneContentSuccess(state, action) {
      state.persistentStateCloneContetnt = action.payload;
    },
    resetState(state) {
      state.facebookUSCaption = '';
      state.facebookCanadaCaption = '';
      state.instagramUSCaption = '';
      state.instagramCanadaCaption = '';
      state.facebookUSTags = { tags: [] };
      state.facebookCanadaTags = { tags: [] };
      state.instagramUSTags = { tags: [] };
      state.instagramCanadaTags = { tags: [] };
      state.sendToFacebook = null;
      state.sendToInstagram = null;
      state.sendToUSCustomers = null;
      state.sendToCanadaCustomers = null;
      state.scheduledFor = '';
      state.type = '';
      state.title = '';
      state.instructions = '';
      state.referenceName = '';
      state.contentIDRedux = '';
      state.campaignIDRedux = '';
      state.persistentStateNewContetnt = false;
      state.persistentStateEditContetnt = false;
      state.persistentStateCloneContetnt = false;
      state.needUpdatePreviewAfterRefresh = false;
      state.postRecordID = '-1';
      state.celeryTaskID = '';
      state.celeryOrPostRecord = 'postRecord';
      state.fileURLs = [];
      state.videoURLs = [];
      state.thumbnailURLs = [];
      state.thumbnailMilliseconds = '';
      state.showUpdatePreview = false;
      state.customizationURL = '';
    },
    getNeedUpdatePreviewAfterRefresh(state, action) {
      state.needUpdatePreviewAfterRefresh = action.payload;
    },
    getPostRecordIDSuccess(state, action) {
      state.postRecordID = action.payload;
    },
    getCeleryTaskIDSuccess(state, action) {
      state.celeryTaskID = action.payload;
    },
    getCeleryOrPostRecordSuccess(state, action) {
      state.celeryOrPostRecord = action.payload;
    },
    getFileURLsSuccess(state, action) {
      state.fileURLs = action.payload;
    },
    getVideoURLsSuccess(state, action) {
      state.videoURLs = action.payload;
    },
    getThumbnailURLsSuccess(state, action) {
      state.thumbnailURLs = action.payload;
    },
    getThumbnailMillisecondsSuccess(state, action) {
      state.thumbnailMilliseconds = action.payload;
    },
    getShowUpdatePreivewSuccess(state, action) {
      state.showUpdatePreview = action.payload;
    },
    getShowMarketingSavedToastSuccess(state, action) {
      state.showMarketingSavedToast = action.payload;
    }
  }
});
// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function setFacebookUSCaptionRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getFacebookUSCaptionSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setFacebookCanadaCaptionRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getFacebookCanadaCaptionSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setInstagramCanadaCaptionRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getInstagramCanadaCaptionSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function setEmailUSCaptionRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getEmailUSCaptionSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function setEmailCanadaCaptionRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getEmailCanadaCaptionSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function setBlogUSCaptionRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getBlogUSCaptionSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function setBlogCanadaCaptionRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getBlogCanadaCaptionSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setInstagramUSCaptionRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getInstagramUSCaptionSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function setFacebookUSTagsRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getFacebookUSTagsSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setFacebookCanadaTagsRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getFacebookCanadaTagsSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setInstagramCanadaTagsRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getInstagramCanadaTagsSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setInstagramUSTagsRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getInstagramUSTagsSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function setEmailCanadaTagsRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getEmailCanadaTagsSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setBlogUSTagsRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getBlogUSTagsSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function setBlogCanadaTagsRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getBlogCanadaTagsSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setEmailUSTagsRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getEmailUSTagsSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function setResourceTypeRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getResourceTypeSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function setSendToFacebookRedux(caption: any) {
  return async () => {
    try {
      dispatch(slice.actions.getSendToFacebookSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setSendToInstagramRedux(caption: any) {
  return async () => {
    try {
      dispatch(slice.actions.getSendToInstagramSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setSendToUSCustomersRedux(caption: any) {
  return async () => {
    try {
      dispatch(slice.actions.getSendToUSCustomersSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setSendToCanadaCustomersRedux(caption: any) {
  return async () => {
    try {
      dispatch(slice.actions.getSendToCanadaCustomersSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setScheduledForRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getScheduledForSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setTypeRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getTypeSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setTitleRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getTitleSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setInstructionsRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getInstructionsSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setDescriptionRedux(description: string) {
  return async () => {
    try {
      dispatch(slice.actions.getDescriptionSuccess(description));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setReferenceNameRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getReferenceNameSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}

export function setCustomizationURLRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getCustomizationURLSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
export function setCampaignIDRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getCampaignIDSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setContentIDRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getContentIDSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setPersistentStateNewContentRedux(caption: boolean) {
  return async () => {
    try {
      dispatch(slice.actions.getPersistentStateNewContentSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function setPersistentStateEditContentRedux(caption: boolean) {
  return async () => {
    try {
      dispatch(slice.actions.getPersistentStateEditContentSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function setPersistentStateCloneContentRedux(caption: boolean) {
  return async () => {
    try {
      dispatch(slice.actions.getPersistentStateCloneContentSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function setResetStateRedux() {
  return async () => {
    try {
      dispatch(slice.actions.resetState());
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function setNeedUpdatePreviewAfterRefresh(caption: boolean) {
  return async () => {
    try {
      dispatch(slice.actions.getNeedUpdatePreviewAfterRefresh(caption));
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function setPostRecordIDRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getPostRecordIDSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setCeleryTaskIDRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getCeleryTaskIDSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setCeleryOrPostRecordRedux(caption: string) {
  return async () => {
    try {
      dispatch(slice.actions.getCeleryOrPostRecordSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setFileURLsRedux(caption: any) {
  return async () => {
    try {
      dispatch(slice.actions.getFileURLsSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setVideoURLsRedux(caption: any) {
  return async () => {
    try {
      dispatch(slice.actions.getVideoURLsSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setThumbnailURLsRedux(caption: any) {
  return async () => {
    try {
      dispatch(slice.actions.getThumbnailURLsSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setThumbnailMillisecondsRedux(caption: any) {
  return async () => {
    try {
      dispatch(slice.actions.getThumbnailMillisecondsSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setShowUpdatePreviewRedux(caption: any) {
  return async () => {
    try {
      dispatch(slice.actions.getShowUpdatePreivewSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setShowMarketingSavedToastRedux(caption: any) {
  return async () => {
    try {
      dispatch(slice.actions.getShowMarketingSavedToastSuccess(caption));
    } catch (error) {
      console.log(error);
    }
  };
}
