//  import { lazy} from 'react';
import { Redirect } from 'react-router';
import { PATH_FIRST_CAMPAIGN_WIZARD } from './paths';
import AgentGuard from '../guards/AgentGuard';
import WizardFirstCampaignLayout from '../layouts/wizardboard/firstCampaignIndex';
import { lazyWithRetry } from './utils';
// ----------------------------------------------------------------------

const WizardMarketingClubRoutes = {
  path: PATH_FIRST_CAMPAIGN_WIZARD.root,
  guard: AgentGuard,
  layout: WizardFirstCampaignLayout,
  routes: [
    // ----------------------------------------------------------------------

    // WIZARD OVERVIEW
    {
      exact: true,
      path: PATH_FIRST_CAMPAIGN_WIZARD.callToAction,
      component: lazyWithRetry(
        () => import('../components/wizard/first_campaign/CallToAction')
      )
    },
    {
      exact: true,
      path: PATH_FIRST_CAMPAIGN_WIZARD.uploadContacts,
      component: lazyWithRetry(
        () => import('../components/wizard/first_campaign/UploadContacts')
      )
    },
    {
      exact: true,
      path: PATH_FIRST_CAMPAIGN_WIZARD.connectSocials,
      component: lazyWithRetry(
        () => import('../components/wizard/first_campaign/ConnectSocials')
      )
    },

    {
      exact: true,
      path: PATH_FIRST_CAMPAIGN_WIZARD.setupAgentIDSite,
      component: lazyWithRetry(
        () => import('../components/wizard/first_campaign/SetupAgentIDSite')
      )
    },

    {
      exact: true,
      path: PATH_FIRST_CAMPAIGN_WIZARD.visitAgentIDSite,
      component: lazyWithRetry(
        () => import('../components/wizard/first_campaign/VisitAgentIDSite')
      )
    },
    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default WizardMarketingClubRoutes;
