import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import {
  Friend,
  Gallery,
  Profile,
  UserPost,
  Follower,
  UserData,
  CreditCard,
  UserInvoice,
  UserManager,
  UserAddressBook,
  NotificationSettings,
  DisplayedUserDetails
} from '../../@types/user';
import { checkRefreshToken } from './utils';

// ----------------------------------------------------------------------

type UserState = {
  displayedUserDetails: null | DisplayedUserDetails;
  isLoading: boolean;
  myProfile: null | Profile;
  posts: UserPost[];
  users: UserData[];
  userList: UserManager[];
  followers: Follower[];
  friends: Friend[];
  gallery: Gallery[];
  cards: CreditCard[] | null;
  addressBook: UserAddressBook[];
  invoices: UserInvoice[];
  notifications: NotificationSettings | null;
  actionItemsLink: string;
  disableExternalLink: boolean;
  initialTimeActionItemClick: number;
  triggerAPICall: boolean;
  linkInBio: string;
  settingsOpen: boolean;
  agentIDURLForMarketing: string;
  error: boolean;
};

const initialState: UserState = {
  displayedUserDetails: null,
  isLoading: false,
  myProfile: null,
  posts: [],
  users: [],
  userList: [],
  followers: [],
  friends: [],
  gallery: [],
  cards: null,
  addressBook: [],
  invoices: [],
  notifications: null,
  actionItemsLink: '',
  disableExternalLink: false,
  initialTimeActionItemClick: 0,
  triggerAPICall: false,
  linkInBio: '',
  settingsOpen: false,
  agentIDURLForMarketing: '',
  error: false
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },
    // GET SELF CUSTOMER PROFILE
    getSelfCustomerProfileSuccess(state, action) {
      const updatedState = { ...state };
      updatedState.displayedUserDetails = action.payload;
      updatedState.isLoading = false;
      return updatedState;
    },

    // GET SELF CUSTOMER PROFILE
    updateSelfAgentProfileSuccess(state, action) {
      const updatedState = { ...state };
      updatedState.displayedUserDetails = action.payload;
      updatedState.isLoading = false;
      return updatedState;
    },
    // UPDATE ALERTS
    updateAlerts(state, action) {
      if (state.displayedUserDetails) {
        state.displayedUserDetails.alerts = action.payload;
      }
    },
    setSettingsOpen(state, action) {
      state.settingsOpen = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // GET FOLLOWERS
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      state.followers = action.payload;
    },

    // ON TOGGLE FOLLOW
    onToggleFollow(state, action) {
      const followerId = action.payload;

      const handleToggle = map(state.followers, (follower) => {
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed
          };
        }
        return follower;
      });

      state.followers = handleToggle;
    },

    // GET FRIENDS
    getFriendsSuccess(state, action) {
      state.isLoading = false;
      state.friends = action.payload;
    },

    // GET GALLERY
    getGallerySuccess(state, action) {
      state.isLoading = false;
      state.gallery = action.payload;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    // GET CARDS
    getCardsSuccess(state, action) {
      state.isLoading = false;
      state.cards = action.payload;
    },

    // GET ADDRESS BOOK
    getAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addressBook = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },
    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },
    // GET ACTION ITEMS LINK
    getActionItemsLinkSuccess(state, action) {
      state.actionItemsLink = action.payload;
    },
    // SET TO DISABLE EXTERNAL ACTION ITEMS LINK
    getDisableExternalActionItemLinkSuccess(state, action) {
      state.disableExternalLink = action.payload;
    },
    // SET THE TIME OF ACTION ITEMS CLICK TO AVOID INFINITE LOOP
    getInitialTimeActionItemClickSuccess(state, action) {
      state.initialTimeActionItemClick = action.payload;
    },
    // TRIGGER THE API CALL LOOP TO SEE IF INFUSION SOFT UPDATED
    getTriggerAPICallSuccess(state, action) {
      state.triggerAPICall = action.payload;
    },
    // GET THE LINK IN BIO KEY
    getLinkInBioSuccess(state, action) {
      state.linkInBio = action.payload;
    },
    getAgentIDURLForMarketingSuccess(state, action) {
      state.agentIDURLForMarketing = action.payload;
    },
    // UPDATE THE ALERT SELECTIONS
    updateAlertSelections(state, action) {
      if (state.displayedUserDetails && state.displayedUserDetails.alerts) {
        try {
          const alertIdx = state.displayedUserDetails.alerts.findIndex(
            (alert) => alert.id === action.payload.alert.id
          );

          let newValue;
          if (!action.payload.checked) {
            newValue = new Date();
          } else {
            newValue = null;
          }

          const new_alerts = [...state.displayedUserDetails.alerts];
          switch (action.payload.type) {
            case 'email':
              new_alerts[alertIdx].email_disabled = newValue;
              state.displayedUserDetails.alerts = new_alerts;
              break;
            case 'text':
              new_alerts[alertIdx].text_disabled = newValue;
              state.displayedUserDetails.alerts = new_alerts;
              break;
            default:
              // Error toast and close modal
              break;
          }
        } catch (error) {
          console.log('Got an error setting alerts settings: ', error);
        }
      }
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  onToggleFollow,
  updateAlertSelections,
  setSettingsOpen
} = slice.actions;

// ----------------------------------------------------------------------

export function getProfile() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/profile');
      dispatch(slice.actions.getProfileSuccess(response.data.profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getSelfCustomerProfile() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/customer/profile/me/');
      dispatch(slice.actions.getSelfCustomerProfileSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCustomerProfileForEmployee(
  customerPk: string,
  event?: Object,
  handleOverviewRedirect?: Function
) {
  return async () => {
    try {
      const response = await axios.get(
        `/user/customers/profile/${customerPk}/`
      );
      dispatch(slice.actions.getSelfCustomerProfileSuccess(response.data));
      if (handleOverviewRedirect) handleOverviewRedirect(event, customerPk);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
type CustomFields = {
  [name: string]: string | null;
};
export function updateSelfAgentProfile(fields: CustomFields) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await checkRefreshToken();
      const response = await axios.post('/customer/profile/me/', fields);
      dispatch(slice.actions.updateSelfAgentProfileSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}
// ----------------------------------------------------------------------

export function setAgentProfileFromValues(fields: any) {
  return async () => {
    try {
      dispatch(slice.actions.updateSelfAgentProfileSuccess(fields));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPosts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/posts');
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFollowers() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/followers');
      dispatch(slice.actions.getFollowersSuccess(response.data.followers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFriends() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/friends');
      dispatch(slice.actions.getFriendsSuccess(response.data.friends));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGallery() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/gallery');
      dispatch(slice.actions.getGallerySuccess(response.data.gallery));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/manage-users');
      dispatch(slice.actions.getUserListSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCards() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/cards');
      dispatch(slice.actions.getCardsSuccess(response.data.cards));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAddressBook() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/address-book');
      dispatch(slice.actions.getAddressBookSuccess(response.data.addressBook));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoices() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/invoices');
      dispatch(slice.actions.getInvoicesSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNotifications() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        '/api/user/account/notifications-settings'
      );
      dispatch(
        slice.actions.getNotificationsSuccess(response.data.notifications)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUsers() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/all');
      dispatch(slice.actions.getUsersSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getActionItemsLink(action: string) {
  return async () => {
    dispatch(slice.actions.getActionItemsLinkSuccess(action));
  };
}

// ----------------------------------------------------------------------

export function getDisableExternalActionItemLink(action: boolean) {
  return async () => {
    dispatch(slice.actions.getDisableExternalActionItemLinkSuccess(action));
  };
}

// ----------------------------------------------------------------------

export function getInitialTimeActionItemClick(action: number) {
  return async () => {
    dispatch(slice.actions.getInitialTimeActionItemClickSuccess(action));
  };
}
// ----------------------------------------------------------------------

export function getTriggerAPICall(action: boolean) {
  return async () => {
    dispatch(slice.actions.getTriggerAPICallSuccess(action));
  };
}
// ----------------------------------------------------------------------

export function getLinkInBio() {
  return async () => {
    try {
      const response = await axios.get('/customer/linkInBio/');
      dispatch(slice.actions.getLinkInBioSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function handleUpdateSettings(id: any, alerts?: any) {
  return async (dispatch: any, getState: any) => {
    const { displayedUserDetails } = getState().user;
    const requestData = {
      alerts_settings:
        alerts && alerts.length > 0 ? alerts : displayedUserDetails?.alerts
    };
    const url = id ? `/user/update-settings/${id}/` : `/user/update-settings/`;
    const { data } = await axios.post(url, requestData);
    dispatch(slice.actions.updateAlerts(data.data));
  };
}

// ----------------------------------------------------------------------

export function getAgentIDURLForMarketing(action: string) {
  return async () => {
    dispatch(slice.actions.getAgentIDURLForMarketingSuccess(action));
  };
}
