import { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { PATH_AUTH, PATH_HOME } from '../routes/paths';
import LoadingScreen from '../components/LoadingScreen';
import Footer from '../components/Footer';

// ----------------------------------------------------------------------

type AuthProtectProps = {
  children: ReactNode;
};

export default function AgentProtect({ children }: AuthProtectProps) {
  const { isLoading, isAuthenticated, user } = useAuth();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Redirect to={PATH_AUTH.login} />;
  }

  if (user.is_customer) {
    return <Redirect to={PATH_HOME.agent} />;
  }

  return (
    <>
      {children}
      <Footer />
    </>
  );
}
