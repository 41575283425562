import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

export default function TextField(theme: Theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .css-etilze-MuiInputBase-input-MuiOutlinedInput-input': {
            padding: '0.75rem 0.875rem',
            paddingLeft: '0'
          }
        }
      }
    }
  };
}
