import { ReactNode } from 'react';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
//

import SubNav from '../../../components/SubNav';
import { PATH_PAGE } from '../../../routes/paths';
// ----------------------------------------------------------------------

// const APP_BAR_MOBILE = 100;
// const APP_BAR_DESKTOP = 100;

const RootStyle = styled('div')(({ theme }) => ({
  // display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
}));

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: '7.75rem',
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: '7.75rem',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const links = [
  { name: 'Overview', route: '/' },
  { name: 'Post History', route: PATH_PAGE.comingSoon },
  { name: 'Analytics', route: PATH_PAGE.comingSoon },
  { name: 'Content', route: PATH_PAGE.comingSoon },
  { name: 'Create Post', route: PATH_PAGE.comingSoon },
  { name: 'Activity Feed', route: PATH_PAGE.comingSoon },
  { name: 'Infusionsoft', route: PATH_PAGE.comingSoon }
];
// ----------------------------------------------------------------------

type StaffLayoutProps = {
  children?: ReactNode;
};

export default function StaffLayout({ children }: StaffLayoutProps) {
  return (
    <RootStyle>
      <SubNav links={links} />
      <MainStyle>{children}</MainStyle>
    </RootStyle>
  );
}
