import { withStyles } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

const calculatedFontSize =
  window.screen.availWidth > 1200
    ? Math.ceil((window.screen.availWidth / 1920) * 16)
    : 16;

const scroll_bar_track_color = 'rgb(235, 235, 235)';
const scroll_bar_color = 'rgb(190, 190, 190)';

const GlobalStyles = withStyles((theme) => ({
  '@global': {
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
      /*
        Scroll Bar Start
      */
      '&::-webkit-scrollbar': {
        backgroundColor: 'transparent',
        width: '17px'
      },
      '&::-webkit-scrollbar-corner': {
        // backgroundColor: scroll_bar_track_color
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '8px',
        backgroundColor: scroll_bar_color,
        minHeight: '24px',
        border: `3px solid ${scroll_bar_track_color}`
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: scroll_bar_track_color,
        borderRadius: '8px'
      }
      /*
        Scroll Bar End
      */
    },
    html: {
      width: '100%',
      height: '100%',
      '-ms-text-size-adjust': '100%',
      '-webkit-overflow-scrolling': 'touch',
      fontSize: calculatedFontSize
    },
    body: {
      width: '100%',
      height: '100%'
    },
    '#root': {
      width: '100%',
      height: '100%'
    },
    input: {
      '&[type=number]': {
        MozAppearance: 'textfield',
        '&::-webkit-outer-spin-button': { margin: 0, WebkitAppearance: 'none' },
        '&::-webkit-inner-spin-button': { margin: 0, WebkitAppearance: 'none' }
      }
    },
    textarea: {
      '&::-webkit-input-placeholder': { color: theme.palette.text.disabled },
      '&::-moz-placeholder': { opacity: 1, color: theme.palette.text.disabled },
      '&:-ms-input-placeholder': { color: theme.palette.text.disabled },
      '&::placeholder': { color: theme.palette.text.disabled }
    },
    a: { color: theme.palette.primary.main },
    img: { display: 'block', maxWidth: '100%' },

    // Lazy Load Img
    '.blur-up': {
      WebkitFilter: 'blur(0.3125rem)',
      filter: 'blur(0.3125rem)',
      transition: 'filter 400ms, -webkit-filter 400ms'
    },
    '.blur-up.lazyloaded ': {
      WebkitFilter: 'blur(0)',
      filter: 'blur(0)'
    },
    '.slick-styled': {
      position: 'static'
    }
  }
}))(() => null);

export default GlobalStyles;
