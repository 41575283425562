import * as Sentry from '@sentry/react';

export default function setSentryTags(payload: any) {
  const anonUser = {
    user: {
      email: 'anonymous',
      name: 'anonymous'
    }
  };
  let tags;
  if (payload.user) {
    tags = payload;
  } else {
    tags = anonUser;
  }
  Sentry.setTag('user.name', tags.user.name);
  Sentry.setTag('user.email', tags.user.email);
}
