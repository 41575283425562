import { remToPx } from '../utils/formatFontSize';
// ----------------------------------------------------------------------

declare module '@material-ui/core/styles/shape' {
  interface Shape {
    borderRadiusSm: number | string;
    borderRadiusMd: number | string;
  }
}

// Can not use rems for these values without throwing errors in the console
const shape = {
  borderRadius: remToPx('0.5'),
  borderRadiusSm: remToPx('0.75'),
  borderRadiusMd: remToPx('1')
};

/*
const shape = {
  borderRadius: '0.5rem',
  borderRadiusSm: '0.75rem',
  borderRadiusMd: '1rem'
};
*/

export default shape;
