import { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Appcues, Loader } from 'appcues-loader';
import { useDispatch } from 'react-redux';
import useAuth from '../hooks/useAuth';
import {
  PATH_AUTH,
  PATH_WIZARD,
  PATH_MARKETING_CLUB_WIZARD,
  PATH_PROFILES
} from '../routes/paths';
import LoadingScreen from '../components/LoadingScreen';
import Footer from '../components/Footer';
import getSSOLink from '../utils/sso';
import { getSelfCustomerProfile } from '../redux/slices/displayedUser';

type AuthProtectProps = {
  children: ReactNode;
};

export default function AgentProtect({ children }: AuthProtectProps) {
  const { isLoading, isAuthenticated, user, displayedUserDetails } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (
      window.location.pathname &&
      window.location.pathname !== '' &&
      window.location.pathname !== '/' &&
      window.location.pathname !== '/auth/sso'
    ) {
      return (
        <Redirect
          to={`${PATH_AUTH.login}?redirect=${window.location.pathname}`}
        />
      );
    }
    return <Redirect to={PATH_AUTH.login} />;
  }
  if (user.is_employee) {
    return <Redirect to={PATH_PROFILES.allAgents} />;
  }

  if (!displayedUserDetails) {
    dispatch(getSelfCustomerProfile());
    return <LoadingScreen />;
  }
  const subscription_type =
    displayedUserDetails?.subscription_type?.toLowerCase() ?? '';

  // If the customer should not be in hub, SSO them to Dashboard/Old Club
  if (['inactive', 'website only'].includes(subscription_type)) {
    const infusionsoft_id =
      displayedUserDetails?.infusionsoft_id?.toString() ?? '';
    const email = user?.email ?? '';
    getSSOLink(infusionsoft_id, email, 'dashboard').then((link) =>
      window.location.replace(link)
    );
    // If we don't return this the code continues on
    // If the code continues on the customer gets a loading spinner in a strange place
    return <></>;
  }

  if (displayedUserDetails?.redirect_to_set_password!) {
    /** This route takes a pk & token as url parms, but it was designed
     * for a redirect where the backend supplies these two variables on
     * redirect from an order form.  The use is not yet authenticated yet.
     * However, in this case, the use is authenticated, they just don't
     * have a good password. So, i'm having the setPassword component hit
     * a authenticated view if the token and pk are auth and auth
     */
    history.push({
      pathname: `/auth/set-password/auth/auth`
    });
  }
  if (
    user.is_customer &&
    displayedUserDetails?.subscription_type === 'Marketing Club' &&
    displayedUserDetails?.redirect_to_wizard!
  ) {
    return <Redirect to={PATH_MARKETING_CLUB_WIZARD.welcome} />;
  }

  if (displayedUserDetails?.redirect_to_wizard!) {
    return <Redirect to={PATH_WIZARD.root} />;
  }

  // Set up the new Appcues loader
  if (user && user.id && displayedUserDetails && !isLoading) {
    const {
      appcues_uuid,
      first_name,
      last_name,
      email,
      subscription_type,
      imported_existing_user,
      infusionsoft_id
    } = displayedUserDetails;
    const loader = new Loader('135939');
    loader.load().then((appcues: Appcues) => {
      appcues.page();
      appcues.identify(appcues_uuid, {
        first_name,
        last_name,
        email,
        subscription_type,
        imported_existing_user,
        ...(infusionsoft_id !== null ? { infusionsoft_id } : {}) // This will spread the infusionsoft_id into main object if it exists, or nothing if it doesn't
      });
    });
  }

  return (
    <>
      {children}
      <Footer />
    </>
  );
}
