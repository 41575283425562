//  import { lazy} from 'react';
import { Redirect } from 'react-router';
import { PATH_WIZARD } from './paths';
import WizardLayout from '../layouts/wizardboard/index';
import WizardGuard from '../guards/WizardGuard';
import { lazyWithRetry } from './utils';
// ----------------------------------------------------------------------

const WizardRoutes = {
  path: PATH_WIZARD.root,
  guard: WizardGuard,
  layout: WizardLayout,
  routes: [
    // ----------------------------------------------------------------------

    // WIZARD OVERVIEW
    {
      exact: true,
      path: PATH_WIZARD.welcome,
      component: lazyWithRetry(
        () => import('../components/wizard/marketing_club/Welcome')
      )
    },
    {
      exact: true,
      path: PATH_WIZARD.setupAgentID,
      component: lazyWithRetry(
        () => import('../components/wizard/marketing_club/SetupAgentID')
      )
    },
    {
      exact: true,
      path: PATH_WIZARD.letsGo,
      component: lazyWithRetry(
        () => import('../components/wizard/marketing_club/LetsGo')
      )
    },
    // {
    //   exact: true,
    //   path: PATH_WIZARD.schedule,
    //   component: lazyWithRetry(() => import('../components/wizard/Schedule'))
    // },
    // {
    //   exact: true,
    //   path: PATH_WIZARD.personalizeYourMarketing,
    //   component: lazyWithRetry(
    //     () => import('../components/wizard/amc/PersonalizeYourMarketing')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_WIZARD.setupContactInfo,
    //   component: lazyWithRetry(
    //     () => import('../components/wizard/amc/SetupContactInfo')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_WIZARD.businessAndBrokerage,
    //   component: lazyWithRetry(
    //     () => import('../components/wizard/amc/BusinessAndBrokerage')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_WIZARD.helpUsGetToKnowYou,
    //   component: lazyWithRetry(
    //     () => import('../components/wizard/amc/HelpUsGetToKnowYou')
    //   )
    // },
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default WizardRoutes;
