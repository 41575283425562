import axios from './axios';

export default async function getSSOLink(
  contact_id: string,
  email: string,
  redir: string
) {
  return axios
    .post('/contacts/getssolink/', {
      contact_id,
      email,
      redir
    })
    .then(
      (response) => `${process.env.REACT_APP_AMC_ROOT}/?${response?.data[0]}`
    );
}
