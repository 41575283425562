import {
  experimentalStyled as styled,
  Box,
  Typography,
  useTheme
} from '@material-ui/core';
import { RiFacebookFill } from 'react-icons/ri';
import { AiFillInstagram } from 'react-icons/ai';
import { FaLinkedinIn } from 'react-icons/fa';
import { BsTwitter } from 'react-icons/bs';

export default function Footer() {
  const FooterContainer = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gridTemplateRows: 'repeat(auto-fit, 1fr)',
    gridGap: `${theme.spacing(3)} ${theme.spacing(8)}`,
    padding: `${theme.spacing(4)} ${theme.spacing(8)}`,
    paddingTop: '0',
    borderTop: '1px solid #DFE3E8',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(4, 1fr)'
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)'
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr'
    }
  }));

  const LogoContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    paddingTop: theme.spacing(3)
  }));

  const CopyrightContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    marginTop: '4rem'
  }));
  const LinkBox = styled(Box)(({ theme }) => ({
    minHeight: '7rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: '.5rem 0'
    },
    '& :not(:first-child)': {
      cursor: 'pointer'
    }
  }));
  const SocialLinkContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '80%',
    justifyContent: 'space-between',
    marginTop: '2rem'
  }));
  const SocialLinkBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center'
  }));

  const TextLink = styled(Typography)(({ theme }) => ({
    margin: '.5rem 0',
    width: 'max-content',
    '&:hover': {
      borderBottom: '1px solid black'
    }
  }));

  const LinkHeader = styled(Typography)(({ theme }) => ({
    marginBottom: '.5rem',
    fontSize: '1rem'
  }));
  const theme = useTheme();
  return (
    <FooterContainer>
      <LogoContainer style={{ gridColumn: '1 / -1' }}>
        <Box
          component="img"
          alt="Logo"
          src="/static/brand/full_logo_svg.svg"
          sx={{
            width: '18rem',
            objectFit: 'contain',
            marginRight: '.5rem',
            transform: 'translateX(-.75rem)'
          }}
        />
      </LogoContainer>
      <LogoContainer sx={{ marginTop: `-${theme.spacing(3)}` }}>
        <Typography>
          The premier marketing content & automation platform for modern real
          estate agents
        </Typography>
        <SocialLinkContainer>
          <SocialLinkBox>
            <RiFacebookFill
              onClick={() =>
                window.open(
                  'https://www.facebook.com/ThePaperlessAgent/',
                  '_blank'
                )
              }
              style={{
                color: '#01ab55',
                height: '2rem',
                width: '2rem',
                cursor: 'pointer'
              }}
            />
          </SocialLinkBox>
          <SocialLinkBox>
            <AiFillInstagram
              onClick={() =>
                window.open(
                  'https://www.instagram.com/thepaperlessagent/',
                  '_blank'
                )
              }
              style={{
                color: '#01ab55',
                height: '2rem',
                width: '2rem',
                cursor: 'pointer'
              }}
            />
          </SocialLinkBox>
          <SocialLinkBox>
            <FaLinkedinIn
              onClick={() =>
                window.open(
                  'https://www.linkedin.com/company/the-paperless-agent/mycompany/',
                  '_blank'
                )
              }
              style={{
                color: '#01ab55',
                height: '2rem',
                width: '2rem',
                cursor: 'pointer'
              }}
            />
          </SocialLinkBox>
          <SocialLinkBox>
            <BsTwitter
              onClick={() =>
                window.open('https://twitter.com/getpaperlessnow', '_blank')
              }
              style={{
                color: '#01ab55',
                height: '2rem',
                width: '2rem',
                cursor: 'pointer'
              }}
            />
          </SocialLinkBox>
        </SocialLinkContainer>
        <CopyrightContainer>
          <Typography>&copy; 2023. All rights reserved.</Typography>
        </CopyrightContainer>
      </LogoContainer>
      <LinkBox>
        <LinkHeader variant="subtitle2">The Paperless Agent</LinkHeader>
        <TextLink
          onClick={() =>
            window.open('https://thepaperlessagent.com/contact/', '_blank')
          }
        >
          Contact Us
        </TextLink>
        <TextLink
          onClick={() =>
            window.open(
              'https://thepaperlessagent.freshdesk.com/support/home',
              '_blank'
            )
          }
        >
          Help Center
        </TextLink>
      </LinkBox>
      <LinkBox>
        <LinkHeader variant="subtitle2">Legal</LinkHeader>
        <TextLink
          onClick={() =>
            window.open('https://thepaperlessagent.com/terms-of-use/', '_blank')
          }
        >
          Terms and Conditions
        </TextLink>
        <TextLink
          onClick={() =>
            window.open(
              'https://thepaperlessagent.com/privacy-policy/',
              '_blank'
            )
          }
        >
          Privacy Policy
        </TextLink>
      </LinkBox>
      <LinkBox>
        <LinkHeader variant="subtitle2">Contact</LinkHeader>
        <Typography>support@thepaperlessagent.com</Typography>
        <Typography>(512) 271-5818</Typography>
      </LinkBox>
    </FooterContainer>
  );
}
