import { ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Cancel';
// material
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
// @types
import { ColorSchema } from '../@types/theme';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => {
  const isLight = theme.palette.mode === 'light';

  const createStyle = {
    color: `${theme.palette.text.primary} !important`,
    backgroundColor: `${theme.palette.background.paper} !important`
  };

  return {
    containerRoot: {
      '& .MuiCollapse-wrapperInner': {
        width: '100%'
      }
    },
    contentRoot: {
      width: '100%',
      padding: theme.spacing(1.5),
      boxShadow: theme.customShadows.z8,
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.grey[isLight ? 0 : 800],
      backgroundColor: theme.palette.grey[isLight ? 900 : 0]
    },
    message: {
      padding: 0,
      fontWeight: theme.typography.fontWeightMedium
    },
    action: {
      marginRight: -4,
      '& svg': {
        width: '1.25rem',
        height: '1.25rem',
        opacity: 0.48,
        '&:hover': { opacity: 1 }
      }
    },
    variantInfo: { ...createStyle },
    variantSuccess: { ...createStyle },
    variantWarning: { ...createStyle },
    variantError: { ...createStyle }
  };
});

// ----------------------------------------------------------------------

type SnackbarIconProps = {
  Icon: any;
  color: ColorSchema;
};

function SnackbarIcon({ Icon, color }: SnackbarIconProps) {
  return (
    <Box
      component="span"
      sx={{
        mr: '0.09375rem',
        width: '2.5rem',
        height: '2.5rem',
        display: 'flex',
        borderRadius: '0.09375rem',
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: 'transparent'
      }}
    >
      <Icon
        sx={{
          width: '1.5rem',
          height: '1.5rem',
          fill: `${color}.main`
        }}
      />
    </Box>
  );
}

type NotistackProviderProps = {
  children: ReactNode;
};

function NotistackProvider({ children }: NotistackProviderProps) {
  const classes = useStyles();

  return (
    <SnackbarProvider
      dense
      maxSnack={5}
      preventDuplicate
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      iconVariant={{
        success: <SnackbarIcon Icon={SuccessIcon} color="success" />,
        error: <SnackbarIcon Icon={ErrorIcon} color="error" />,
        warning: <SnackbarIcon Icon={WarningIcon} color="warning" />,
        info: <SnackbarIcon Icon={InfoIcon} color="info" />
      }}
      classes={classes}
    >
      {children}
    </SnackbarProvider>
  );
}

export default NotistackProvider;
