import { Box, BoxProps } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function Logo(props: BoxProps) {
  let logo = '';

  let environment =
    process.env.REACT_APP_SENTRY_ENVIRONMENT?.toLowerCase() || '';
  if (environment.includes('local')) {
    const [name, getEnvironment] = environment.split('-');
    environment = getEnvironment;
  }

  switch (environment) {
    case 'staging':
      logo = '/static/brand/asset_4mdpi.png';
      break;
    case 'local':
      logo = '/static/brand/asset_3mdpi.png';
      break;
    case 'dev':
      logo = '/static/brand/asset_3mdpi.png';
      break;
    case 'production':
    default:
      logo = '/static/brand/asset_2mdpi.png';
      break;
  }

  return (
    <Box component="img" alt="logo" src={logo} height="2.5rem" {...props} />
  );
}
