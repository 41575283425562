//  import { lazy } from 'react';
import { Redirect } from 'react-router';
import { PATH_AGENTS, PATH_HOME } from './paths';
import AgentLayout from '../layouts/agentboard/index';
import AgentGuard from '../guards/AgentGuard';
import { lazyWithRetry } from './utils';
//  import { componentLoader } from './utils';
// ----------------------------------------------------------------------

const AgentRoutes = {
  path: PATH_HOME.agent,
  guard: AgentGuard,
  layout: AgentLayout,
  routes: [
    // ----------------------------------------------------------------------
    // PROFILE OVERVIEW
    {
      exact: true,
      path: PATH_AGENTS.root,
      component: lazyWithRetry(() => import('../views/agent/Overview'))
    },
    {
      exact: true,
      path: PATH_AGENTS.action,
      component: lazyWithRetry(() => import('../views/ComingSoon'))
    },
    {
      exact: true,
      path: PATH_AGENTS.calendar,
      component: lazyWithRetry(() => import('../views/ComingSoon'))
    },
    {
      exact: true,
      path: PATH_AGENTS.reports,
      component: lazyWithRetry(() => import('../views/ComingSoon'))
    },
    {
      exact: true,
      path: PATH_AGENTS.club,
      component: lazyWithRetry(() => import('../views/ComingSoon'))
    },
    {
      exact: true,
      path: PATH_AGENTS.contacts,
      component: lazyWithRetry(() => import('../views/agent/Contacts'))
    },
    {
      exact: true,
      path: PATH_AGENTS.contact_sync,
      component: lazyWithRetry(() => import('../views/agent/ContactSync'))
    },
    {
      exact: true,
      path: PATH_AGENTS.hotsheet,
      component: lazyWithRetry(() => import('../views/agent/Hotsheet'))
    },
    {
      exact: true,
      path: PATH_AGENTS.account,
      component: lazyWithRetry(() => import('../views/agent/Account'))
    },
    {
      exact: true,
      path: PATH_AGENTS.posts,
      component: lazyWithRetry(() => import('../views/agent/Posts'))
    },
    {
      exact: true,
      path: PATH_AGENTS.allCampaigns,
      component: lazyWithRetry(
        () => import('../views/marketing-club/AllCampaigns')
      )
    },
    {
      exact: true,
      path: PATH_AGENTS.yourCampaigns,
      component: lazyWithRetry(
        () => import('../views/marketing-club/YourCampaigns')
      )
    },
    {
      exact: true,
      path: PATH_AGENTS.campaignPreview,
      component: lazyWithRetry(
        () => import('../views/marketing-club/CampaignPreview')
      )
    },
    {
      exact: true,
      path: PATH_AGENTS.campaignSetup,
      component: lazyWithRetry(
        () => import('../views/marketing-club/CampaignSetup')
      )
    },
    {
      exact: true,
      path: PATH_AGENTS.campaignDirector,
      component: lazyWithRetry(
        () => import('../views/marketing-club/CampaignDirector')
      )
    },
    {
      exact: true,
      path: PATH_AGENTS.campaignDefault,
      component: lazyWithRetry(
        () => import('../views/marketing-club/CampaignDirector')
      )
    },
    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AgentRoutes;
