import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import userReducer from './slices/displayedUser';
import authJwtReducer from './slices/authJwt';
import settingsReducer from './slices/settings';
import campaignManagerReducer from './slices/campaignManager';
import postNowReducer from './slices/postNow';
import calendarReducer from './slices/calendar';
import todoListReducer from './slices/todoList';
import contactsReducer from './slices/contacts';
// slices
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const authPersistConfig = {
  key: 'authJwt',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated']
};

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  user: userReducer,
  settings: settingsReducer,
  authJwt: persistReducer(authPersistConfig, authJwtReducer),
  campaignManager: campaignManagerReducer,
  postNow: postNowReducer,
  calendar: calendarReducer,
  todoList: todoListReducer,
  contacts: contactsReducer
});

export { rootPersistConfig, rootReducer };
