import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useRef, useState, useEffect } from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonOutline from '@material-ui/icons/PersonOutline';
import { useHistory, Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { alpha } from '@material-ui/core/styles';
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
  Switch,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  useMediaQuery,
  ButtonBase
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import closeFill from '@iconify/icons-eva/close-fill';
import { RootState } from '../../redux/store';
import axiosInstance from '../../utils/axios';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import { MIconButton } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';
import handleLogoutRedirect from '../../utils/handleExpiredRefreshToken';
import {
  // updateAlertSelections,
  handleUpdateSettings
  // setSettingsOpen
} from '../../redux/slices/displayedUser';
import { triggerInteractionUpdateList } from '../../redux/slices/todoList';
import { setSettingsModal } from '../../redux/slices/authJwt';
import { isEmployee } from '../../utils/getUserType';
import getSSOLink from '../../utils/sso';
// ----------------------------------------------------------------------

export default function AccountPopover(props: any) {
  const { onlyRunSettingAppcuesOnceRef } = props;
  const history = useHistory();
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  // const [settingsOpen, setSettingsOpen] = useState(false);
  const [showBadPhone, setShowBadPhone] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { displayedUserDetails } = useAuth();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const location = useLocation();
  const URLpathname = useLocation().pathname;
  const { pathname } = location;
  const [ssoLink, setSSOLink] = useState('');
  const [alerts, setAlerts] = useState(displayedUserDetails?.alerts);
  // const { opt_in_email_alerts, opt_in_text_alerts } = useSelector(
  //   (state: RootState) => state.user
  // );
  const { openSettingsModal } = useSelector(
    (state: RootState) => state.authJwt
  );

  useEffect(() => {
    if (displayedUserDetails) {
      getSSOLink(
        displayedUserDetails.infusionsoft_id,
        user.email,
        'dashboard'
      ).then((link) => setSSOLink(link));
    }
  }, [displayedUserDetails, user]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleModalClose = () => {
    dispatch(setSettingsModal({ settingsModal: false }));
  };

  const handleLogout = async () => {
    if (!user.is_employee) {
      handleLogoutRedirect();
      return;
    }
    try {
      logout();
      if (isMountedRef.current) {
        history.push('/');
        handleClose();
      }
    } catch (error) {
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  async function handleSave() {
    try {
      dispatch(handleUpdateSettings(null, alerts));
      // setSettingsOpen(false);
      enqueueSnackbar('Setting Updated!', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      dispatch(setSettingsModal({ settingsModal: false }));
    } catch (err) {
      enqueueSnackbar('Problem Updating Settings', { variant: 'error' });
    }
  }

  // Create a todolist interaction
  useEffect(() => {
    if (
      !isEmployee() &&
      openSettingsModal &&
      onlyRunSettingAppcuesOnceRef.current
    ) {
      onlyRunSettingAppcuesOnceRef.current = false;
      if (!user.is_employee) {
        axiosInstance.post('todo/create_interaction/', {
          action_type: 'has_opened_settings_modal'
        });
      }
      dispatch(
        triggerInteractionUpdateList(
          'todo/create_interaction/',
          'has_registered_advanced_training',
          URLpathname,
          displayedUserDetails,
          user
        )
      );
    }
  }, []);

  const getOpenSettingModal = (settingModal: any) => {
    if (typeof settingModal == 'boolean') {
      return settingModal;
    }
    return settingModal?.settingsModal;
  };

  const handleUpdateAlert = (alert: any, checked: boolean, type: string) => {
    setAlerts((prev: any) =>
      prev.map((item: any) => ({
        ...item,
        email_disabled:
          type === 'email' && alert.id === item.id
            ? checked
              ? null
              : new Date()
            : item.email_disabled,
        text_disabled:
          type === 'text' && alert.id === item.id
            ? checked
              ? null
              : new Date()
            : item.text_disabled
      }))
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        // Without this the photo button will top-align on small sizes
        '& > div': {
          alignItems: 'center'
        }
      }}
    >
      {!isEmployee() && (
        // Using ButtonBase to add ripple effect
        <ButtonBase
          onClick={handleOpen}
          color="inherit"
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            marginRight: isDesktop ? theme.spacing(1) : '0',
            outline: 'none',
            border: 'none',
            // Hack to get border with that does not extend past icon and text
            '&:after':
              pathname === '/account'
                ? {
                    content: '""',
                    display: 'block',
                    width: '95%',
                    background: theme.palette.primary.main,
                    position: 'absolute',
                    height: '0.1875rem',
                    top: '100%',
                    left: '0px'
                  }
                : {},
            '&:hover': {
              backgroundColor: '#fff'
            }
          }}
        >
          <SettingsIcon
            style={{
              color:
                pathname === '/account'
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary
            }}
            sx={{
              mt: '0.1975rem',
              mx: isDesktop ? 0 : '0.125rem'
            }}
          />
          {isDesktop && (
            <Typography
              variant="body1"
              color={
                pathname === '/account'
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary
              }
              sx={{
                lineHeight: '1'
              }}
            >
              My Account
            </Typography>
          )}
        </ButtonBase>
      )}

      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: '2.75rem',
          height: '2.75rem',
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <MyAvatar />
      </MIconButton>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: '13.75rem' }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {displayedUserDetails?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {displayedUserDetails?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />
        {!isEmployee() ? (
          <>
            <Link
              to="/account"
              component={RouterLink}
              sx={{
                color: 'text.primary',
                '&:hover': {
                  textDecoration: 'none'
                }
              }}
            >
              <MenuItem sx={{ typography: 'body2', py: 1, px: 2.5 }}>
                <PersonOutline sx={{ mr: theme.spacing(2) }} />
                <Typography variant="body2">Account</Typography>
              </MenuItem>
            </Link>
            <Link
              to={{ pathname: ssoLink }}
              component={RouterLink}
              target="_blank"
              sx={{
                color: 'text.primary',
                '&:hover': {
                  textDecoration: 'none'
                }
              }}
            >
              <MenuItem sx={{ typography: 'body2', py: 1, px: 2.5 }}>
                <DashboardIcon sx={{ mr: theme.spacing(2) }} />
                <Typography variant="body2">Club Classic</Typography>
              </MenuItem>
            </Link>
            <MenuItem
              onClick={() =>
                dispatch(setSettingsModal({ settingsModal: true }))
              }
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              <SettingsIcon sx={{ mr: theme.spacing(2) }} />
              Settings
            </MenuItem>
          </>
        ) : (
          ''
        )}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
      <Dialog
        open={getOpenSettingModal(openSettingsModal)}
        onClose={() => handleModalClose()}
        maxWidth="xs"
        fullWidth
        sx={{ margin: theme.spacing(3) }}
        disableScrollLock={true}
      >
        <Box>
          <DialogTitle>
            <Typography variant="subtitle1" noWrap>
              {displayedUserDetails?.name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {displayedUserDetails?.email}
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  gap: theme.spacing(7)
                }}
              >
                <FormControl component="fieldset" style={{ display: 'flex' }}>
                  <Box style={{ display: 'flex', gap: theme.spacing(2) }}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: theme.spacing(3.75),
                        marginTop: '2.8rem'
                      }}
                    >
                      {displayedUserDetails &&
                        displayedUserDetails.alerts &&
                        displayedUserDetails?.alerts.map((alert, idx) => (
                          <Typography style={{ textAlign: 'right' }} key={idx}>
                            {alert.name}:
                          </Typography>
                        ))}
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: theme.spacing(2)
                      }}
                    >
                      <Typography>Email Alerts</Typography>
                      {displayedUserDetails &&
                        alerts &&
                        alerts?.map((alert, idx) => (
                          <FormControlLabel
                            key={idx}
                            control={
                              <Switch
                                checked={!alert.email_disabled}
                                onChange={(event) =>
                                  handleUpdateAlert(
                                    alert,
                                    event.target.checked,
                                    'email'
                                  )
                                }
                                color="primary"
                              />
                            }
                            label="Opt In"
                          />
                        ))}
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: theme.spacing(2)
                      }}
                    >
                      <Typography>Text Alerts</Typography>
                      {displayedUserDetails &&
                        alerts &&
                        alerts.map((alert, idx) => (
                          <FormControlLabel
                            key={idx}
                            control={
                              <Switch
                                checked={!alert.text_disabled}
                                onChange={(event) =>
                                  handleUpdateAlert(
                                    alert,
                                    event.target.checked,
                                    'text'
                                  )
                                }
                                color="primary"
                              />
                            }
                            label="Opt In"
                          />
                        ))}
                    </Box>
                  </Box>
                </FormControl>
              </Box>
              {showBadPhone ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: theme.spacing(2)
                  }}
                >
                  <FormHelperText error>
                    Phone number must be a valid 10 digit number. Please change
                    your number in your Account
                  </FormHelperText>
                </Box>
              ) : (
                ''
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: theme.spacing(2)
                }}
              >
                <Button variant="outlined" onClick={handleSave}>
                  Save
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </Box>
  );
}
