import { useDispatch, useSelector } from 'react-redux';
// redux
import { RootState } from '../redux/store';
import { login, register, logout, getUser } from '../redux/slices/authJwt';
// ----------------------------------------------------------------------

type LoginParams = {
  email: string;
  password: string;
};

type RegisterParams = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export default function useAuth() {
  // JWT Auth
  const dispatch = useDispatch();
  const { user, isLoading, isAuthenticated, urlParams } = useSelector(
    (state: RootState) => state.authJwt
  );
  const { displayedUserDetails } = useSelector(
    (state: RootState) => state.user
  );

  // JWT Auth
  return {
    method: 'jwt',
    user,
    displayedUserDetails,
    isLoading,
    isAuthenticated,
    urlParams,

    login: ({ email, password }: LoginParams) =>
      dispatch(
        login({
          email,
          password
        })
      ),

    getUser: () => dispatch(getUser()),
    register: ({ email, password, firstName, lastName }: RegisterParams) =>
      dispatch(
        register({
          email,
          password,
          firstName,
          lastName
        })
      ),

    logout: () => dispatch(logout()),

    resetPassword: (email: string) => {},

    updateProfile: (data: any) => {}
  };
}
