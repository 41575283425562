import { Router } from 'react-router-dom';
import { useEffect } from 'react';
import { createBrowserHistory } from 'history';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// material
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
// redux
import { store, persistor } from './redux/store';
// routes
import routes, { renderRoutes } from './routes';
// theme
import ThemeConfig from './theme';
// components
//  import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';

// Using for Auth (Check doc https://minimals.cc/docs/authentication)
import JwtProvider from './components/authentication/JwtProvider';
import devConsole from './utils/devConsole';

// import FirebaseProvider from './components/authentication/FirebaseProvider';

// ----------------------------------------------------------------------

const history = createBrowserHistory();

export default function App() {
  devConsole?.log(`This is ${process.env.REACT_APP_SENTRY_ENVIRONMENT}`);
  // We used to have check tokens match here to verify that the logged in user
  // the matched the current user.  This is to prevent anyone but mainly
  // employees not be logged in as a customer and an employee.  However,
  // just by importing the root state here caused the entire route tree
  // to be re-rendered whenever there was a change in tokens.  It was not
  // an issue with useEffect that could be solved with another hook like
  // useCallback or useMemo. Just by importing RootState and isAuthenticated
  // from redux causes a re-render from a state change.

  // DO NOT IMPORT REDUX STATE OR USESTATE HERE, BUT FARTHER DOWN IN CHILDREN COMPONENTS

  useEffect(() => {
    const iconArray = ['apple', '16', '32'];
    let environment = process.env.REACT_APP_SENTRY_ENVIRONMENT?.toLowerCase();
    if (environment ? environment.includes('local') : false) {
      environment = environment ? environment.split('-')[1] : 'Undefined';
    }
    iconArray.forEach((icon) => {
      const favicon = document.getElementById(
        `favicon-${icon}`
      ) as HTMLAnchorElement;
      switch (environment) {
        case 'local':
          favicon.href = '/favicon/favicon-dev.ico';
          break;
        case 'dev':
          favicon.href = '/favicon/favicon-dev.ico';
          break;
        case 'staging':
          favicon.href = '/favicon/favicon-staging.ico';
          break;
        case 'production':
          favicon.href = '/favicon/favicon-prod.ico';
          break;
        default:
          favicon.href = '/favicon/favicon-prod.ico';
          break;
      }
    });
  }, []);

  return (
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={<LoadingScreen />} persistor={persistor}>
          <ThemeConfig>
            <RtlLayout>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <NotistackProvider>
                  <Router history={history}>
                    <JwtProvider>
                      {/* <Settings /> */}
                      <ScrollToTop />
                      <GoogleAnalytics />
                      {renderRoutes(routes)}
                    </JwtProvider>
                  </Router>
                </NotistackProvider>
              </LocalizationProvider>
            </RtlLayout>
          </ThemeConfig>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  );
}
