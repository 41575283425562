import * as Sentry from '@sentry/react';
import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
// import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------
// Here is the initial state
const todolistInitialState = {
  id: 0,
  title: '',
  subtitle: '',
  label: '',
  order_number: 0,
  type: '',
  created_at: null,
  completed_at: null,
  appcues_flow_id: '',
  personal_tasks: [],
  todoLoading: false
};

const slice = createSlice({
  name: 'todoList',
  initialState: todolistInitialState,
  reducers: {
    setCurrentTodolist(state, action) {
      state.id = action.payload.id;
      state.title = action.payload.title;
      state.subtitle = action.payload.subtitle;
      state.label = action.payload.label;
      state.order_number = action.payload.order_number;
      state.type = action.payload.type;
      state.created_at = action.payload.created_at;
      state.completed_at = action.payload.completed_at;
      state.appcues_flow_id = action.payload.appcues_flow_id;
      state.personal_tasks = action.payload.personal_tasks;
    },
    appcuesListTrigger(state) {
      if (state.appcues_flow_id) {
        window.Appcues.show(state.appcues_flow_id);
      }
    },
    setTodoLoading(state, action) {
      state.todoLoading = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setCurrentTodolist, setTodoLoading } = slice.actions;

// ----------------------------------------------------------------------

export function getPageTodoList(url: string, user: any) {
  return async (dispatch: any) => {
    try {
      dispatch(slice.actions.setTodoLoading(true));
      const response = await axiosInstance.get(url);
      if (response.data.personal_list) {
        dispatch(slice.actions.setCurrentTodolist(response.data.personal_list));
        dispatch(slice.actions.setTodoLoading(false));

        // Check if an appcues flow should be triggered
        let taskForAppcues = null;
        if (response?.data?.personal_list?.personal_tasks) {
          [taskForAppcues] = response.data.personal_list.personal_tasks.filter(
            (task: any) =>
              task.should_show_appcues &&
              task.appcues_flow_id &&
              task.appcues_flow_id !== '' &&
              !task.appcue_triggered &&
              task.completed_at
          );
        }

        // Trigger the task appcues flow, and update the backend to mark the task as triggered
        if (taskForAppcues && user.is_customer) {
          window.Appcues.show(taskForAppcues.appcues_flow_id);
          axiosInstance.post(`todo/update_appcue/`, {
            task: taskForAppcues.id
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function createNextList(url: string, user: any) {
  return async (dispatch: any) => {
    try {
      const response = await axiosInstance.post(url);
      if (user.is_customer) {
        dispatch(slice.actions.appcuesListTrigger());
      }
      dispatch(slice.actions.setCurrentTodolist(response.data.personal_list));
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function updatePageTodoList(list: any, user: any) {
  return async (dispatch: any) => {
    try {
      // First we need to get the current list and the new incoming list
      const response = await axiosInstance.get(
        `todo/personal_list/${list && `${list}/`}`
      );
      const newList = response.data.personal_list;

      // Check if a TASK appcues flow should be triggered
      const taskForAppcues = newList.personal_tasks
        .filter(
          (task: any) =>
            task.should_show_appcues &&
            task.appcues_flow_id &&
            task.appcues_flow_id !== '' &&
            !task.appcue_triggered &&
            task.completed_at
        )
        .pop();

      // Trigger TASK appcues flow, and update the backend to mark the task as triggered
      if (taskForAppcues && user.is_customer) {
        window.Appcues.show(taskForAppcues.appcues_flow_id);
        axiosInstance.post(`todo/update_appcue/`, {
          task: taskForAppcues.id
        });
      }

      // Finally set the new list in state
      dispatch(slice.actions.setCurrentTodolist(newList));
    } catch (error) {
      Sentry.captureException(`Failed to fetch marketings: ${error}`);
    }
  };
}

// ----------------------------------------------------------------------

export function triggerInteractionUpdateList(
  interactionRequestUrl: string,
  interactionTaskString: string,
  URLpathname: string,
  displayedUserDetails: any,
  user: any,
  campaign_pk?: null | number
) {
  return async (dispatch: any) => {
    try {
      // Create the interaction request
      if (campaign_pk) {
        axiosInstance.post(interactionRequestUrl, {
          action_type: interactionTaskString,
          campaign_pk
        });
      } else {
        axiosInstance.post(interactionRequestUrl, {
          action_type: interactionTaskString
        });
      }

      // Handle routing based on URL path
      if (URLpathname === '/' && displayedUserDetails) {
        dispatch(updatePageTodoList(displayedUserDetails?.overview_list, user));
      } else if (URLpathname.includes('/campaign')) {
        // Split the path and destructure the relevant parts
        const [, secondPart, thirdPart] = URLpathname.split('/');
        let campaignId = null;
        let updateUrl = null;

        // Try to assign a valid campaignId
        if (!isNaN(Number(secondPart))) {
          campaignId = secondPart;
          updateUrl = `campaign/${campaignId}`;
        } else if (!isNaN(Number(thirdPart))) {
          campaignId = thirdPart;
          updateUrl = `campaign/${campaignId}`;
        }

        // Dispatch the action to update the page Todo List
        if (campaignId) {
          dispatch(updatePageTodoList(updateUrl, user));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
}
