//  import { lazy } from 'react';
import { Redirect } from 'react-router';
import { PATH_PROFILES } from './paths';
import DashboardLayout from '../layouts/staffboard/index';
import StaffGuard from '../guards/StaffGuard';
import { lazyWithRetry } from './utils';

const ProfileRoutes = {
  path: PATH_PROFILES.root,
  guard: StaffGuard,
  layout: DashboardLayout,
  routes: [
    // ----------------------------------------------------------------------
    // PROFILE OVERVIEW
    {
      exact: true,
      path: PATH_PROFILES.allAgents,
      component: lazyWithRetry(
        () => import('../components/profile-overview/AllAgents')
      )
    },
    {
      exact: true,
      path: PATH_PROFILES.newAMCAgents,
      component: lazyWithRetry(
        () => import('../components/profile-overview/NewAgents')
      )
    },
    {
      exact: true,
      path: PATH_PROFILES.pausedAccounts,
      component: lazyWithRetry(
        () => import('../components/profile-overview/PausedAccounts')
      )
    },
    {
      exact: true,
      path: PATH_PROFILES.newMCAgents,
      component: lazyWithRetry(
        () => import('../components/profile-overview/NewAgents')
      )
    },
    {
      exact: true,
      path: PATH_PROFILES.tasks,
      component: lazyWithRetry(() => import('../views/ComingSoon'))
    },
    {
      exact: true,
      path: PATH_PROFILES.nylas_redirect,
      component: lazyWithRetry(
        () => import('../components/contacts/NylasRedirect')
      )
    },
    // {
    //   exact: true,
    //   path: PATH_PROFILES.pausedAccounts,
    //   component: lazyWithRetry(
    //     () => import('../components/profile-overview/PausedAccounts')
    //   )
    // },
    // pausedAccounts
    // ----------------------------------------------------------------------

    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default ProfileRoutes;
