import { Box, Checkbox } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Timeline,
  TimelineContent,
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@material-ui/lab';
import React from 'react';

type Props = {
  latestIndex: any;
  onFetch: any;
  pagesOrder: any;
  currentIndex: any;
};

const TimeLineContentStyle = styled(Box)(({ theme }) => ({
  '&.current': {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    padding: '0.1875rem 1rem 0.3125rem 1rem',
    width: 'fit-content',
    borderRadius: 5
  },
  '&.completed': {
    fontWeight: theme.typography.fontWeightBold,
    cursor: 'pointer'
  },
  '&.incomplete': {
    fontStyle: 'italic'
  }
}));

const Map = ({ latestIndex, onFetch, pagesOrder, currentIndex }: Props) => {
  const lastPage = pagesOrder.length - 1;
  const redirect = (url: string, idx: number) => {
    onFetch(url, idx);
  };

  const statusClass = (idx: number) => {
    const cls = 'current';
    if (idx === currentIndex && idx < latestIndex) {
      return `${cls} completed`;
    }
    if (idx === currentIndex && idx === latestIndex) {
      return `${cls}`;
    }
    if (idx <= latestIndex) {
      return 'completed';
    }
    return 'incomplete';
  };

  const timeLineNodes = pagesOrder.map((page_order: any, index: number) => (
    <TimelineItem style={{ height: '4.375rem', minHeight: 0 }} key={index}>
      <TimelineOppositeContent
        style={{ flex: '0', flexBasis: '0', padding: '0' }}
      />
      <TimelineSeparator sx={{ flexBasis: '2.5rem' }}>
        {index < latestIndex ? (
          <Checkbox checked={true} sx={{ pointerEvents: 'none' }} />
        ) : (
          <TimelineDot
            color="secondary"
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '0.71875rem',
              marginBottom: '0.71875rem',
              height: '0.75rem',
              width: '0.75rem'
            }}
          />
        )}
        {index !== lastPage && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent
        onClick={
          index <= latestIndex
            ? () => redirect(page_order.path, index)
            : undefined
        }
      >
        <TimeLineContentStyle className={statusClass(index)}>
          {page_order.name}
        </TimeLineContentStyle>
      </TimelineContent>
    </TimelineItem>
  ));

  return <Timeline>{timeLineNodes}</Timeline>;
};

export default Map;
