import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axiosInstance from '../../utils/axios';
// ----------------------------------------------------------------------

type PostNowState = {
  sendToAll: boolean;
  toPostToExcluded: any;
  toPostTo: any;
  batchCampaignOrBatchContent: string;
  startDate: any;
  batchCampaign: any;
  batchContents: any;
  batchContentsDict: any;
  batchSchedules: any;
  compatibleCount: number;
  batchPostRecordCeleryTaskID: string;
  searchCompatible: string;
};
const initialPostNowState: PostNowState = {
  sendToAll: false,
  toPostToExcluded: [],
  toPostTo: [],
  batchCampaignOrBatchContent: 'batchContent',
  startDate: new Date(),
  batchCampaign: {},
  batchContents: [],
  batchContentsDict: {},
  batchSchedules: {},
  compatibleCount: 0,
  batchPostRecordCeleryTaskID: '',
  searchCompatible: ''
};

const slice = createSlice({
  name: 'postNow',
  initialState: initialPostNowState,
  reducers: {
    getSendToAllSuccess(state, action) {
      state.sendToAll = action.payload;
    },
    getToPostToExcludedSuccess(state, action) {
      state.toPostToExcluded = action.payload;
    },
    getToPostToSuccess(state, action) {
      state.toPostTo = action.payload;
    },
    getCampaignOrContentSuccess(state, action) {
      state.batchCampaignOrBatchContent = action.payload;
    },
    getStartDateSuccess(state, action) {
      state.startDate = action.payload;
    },
    getBatchCampaignSuccess(state, action) {
      state.batchCampaign = action.payload;
    },
    getBatchContentsSuccess(state, action) {
      state.batchContents = action.payload;
    },
    getBatchContentsDictSuccess(state, action) {
      state.batchContentsDict = action.payload;
    },
    getBatchSchedulesSuccess(state, action) {
      state.batchSchedules = action.payload;
    },
    getCompatibleCountSuccess(state, action) {
      state.compatibleCount = action.payload;
    },
    getSearchCompatibleSuccess(state, action) {
      state.searchCompatible = action.payload;
    },
    batchPostRecordCeleryTaskIDSuccess(state, action) {
      state.batchPostRecordCeleryTaskID = action.payload;
    },
    resetState(state) {
      state.sendToAll = false;
      state.toPostToExcluded = [];
      state.toPostTo = [];
      state.batchCampaignOrBatchContent = 'batchContent';
      state.startDate = new Date();
      state.batchCampaign = {};
      state.batchContents = [];
      state.batchContentsDict = {};
      state.batchSchedules = {};
      state.compatibleCount = 0;
      state.searchCompatible = '';
    }
  }
});
// Reducer
export default slice.reducer;
// ----------------------------------------------------------------------

export function setBatchPostRecordCeleryTaskID(state: any) {
  return async () => {
    try {
      dispatch(slice.actions.batchPostRecordCeleryTaskIDSuccess(state));
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function resetStateRedux() {
  return async () => {
    try {
      dispatch(slice.actions.resetState());
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setSendToAllSuccessRedux(state: boolean) {
  return async () => {
    try {
      dispatch(slice.actions.getSendToAllSuccess(state));
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------
const listToDictSchedule = (list: any) => {
  const dict: any = {};
  for (let i = 0; i < list.length; i++) {
    dict[list[i].template_content_pk] = list[i];
  }
  return dict;
};
export function setRawSchedulesRedux(state: any) {
  return async () => {
    try {
      dispatch(
        slice.actions.getBatchSchedulesSuccess(listToDictSchedule(state))
      );
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setToPostToExcludedRedux(state: any) {
  return async () => {
    try {
      dispatch(slice.actions.getToPostToExcludedSuccess(state));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setCompatibleCountRedux(state: any) {
  return async () => {
    try {
      dispatch(slice.actions.getCompatibleCountSuccess(state));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function setToPostToRedux(state: any) {
  return async () => {
    try {
      dispatch(slice.actions.getToPostToSuccess(state));
    } catch (error) {
      console.log(error);
    }
  };
}

export function setCampaignOrContentRedux(state: any) {
  return async () => {
    try {
      dispatch(slice.actions.getCampaignOrContentSuccess(state));
    } catch (error) {
      console.log(error);
    }
  };
}

export function setSearchCompatibleRedux(state: any) {
  return async () => {
    try {
      dispatch(slice.actions.getSearchCompatibleSuccess(state));
    } catch (error) {
      console.log(error);
    }
  };
}

export function setStartDateRedux(newStartDate: any) {
  return async () => {
    try {
      let startDateObj: any = new Date();
      if (typeof newStartDate == 'string') {
        startDateObj = new Date(newStartDate);
      } else {
        startDateObj = newStartDate;
      }
      dispatch(slice.actions.getStartDateSuccess(startDateObj));
    } catch (error) {
      console.log(error);
    }
  };
}

const listToDictContent = (list: any, scheduleDict: any) => {
  const dict: any = {};
  for (let i = 0; i < list.length; i++) {
    try {
      const newObj = { ...list[i] };
      newObj.scheduled_for = new Date(scheduleDict[newObj.id].scheduled_for);
      dict[list[i].id] = newObj;
    } catch (err) {
      const a = 1;
    }
  }
  return dict;
};
export function setCampaignSchedulesRedux(campaignID: any, startDate: any) {
  return async () => {
    try {
      const response = await axiosInstance.get(
        `/marketing/create_batch_campaign/${campaignID}/${startDate}/`
      );
      dispatch(
        slice.actions.getBatchCampaignSuccess(response.data.batch_campaign)
      );
      dispatch(
        slice.actions.getBatchContentsSuccess(response.data.batch_contents)
      );
      const schedulesDict = listToDictSchedule(response.data.batch_schedules);
      dispatch(slice.actions.getBatchSchedulesSuccess(schedulesDict));
      dispatch(
        slice.actions.getBatchContentsDictSuccess(
          listToDictContent(response.data.batch_contents, schedulesDict)
        )
      );
    } catch (error) {
      console.log(error);
    }
  };
}

export function setBatchContentsDictRedux(state: any, scheduler_list: any) {
  return async () => {
    try {
      const schedulesDict = listToDictSchedule(scheduler_list);
      dispatch(slice.actions.getBatchSchedulesSuccess(schedulesDict));
      dispatch(
        slice.actions.getBatchContentsDictSuccess(
          listToDictContent(state, schedulesDict)
        )
      );
    } catch (error) {
      console.log(error);
    }
  };
}

export function setBatchContentsRedux(state: any) {
  return async () => {
    try {
      dispatch(slice.actions.getBatchContentsSuccess(state));
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateSchedulesRedux(
  batchCampaignID: any,
  startDate: any,
  overwrite_customized: boolean
) {
  return async () => {
    try {
      dispatch(slice.actions.getStartDateSuccess(startDate));
      let url = '';
      if (overwrite_customized) {
        url = `/marketing/create_batch_campaign/${batchCampaignID}/${startDate}/?id_type=batch&overwrite_customized=true`;
      } else {
        url = `/marketing/create_batch_campaign/${batchCampaignID}/${startDate}/?id_type=batch`;
      }
      const response = await axiosInstance.get(url);
      dispatch(
        slice.actions.getBatchCampaignSuccess(response.data.batch_campaign)
      );
      const schedulesDict = listToDictSchedule(response.data.batch_schedules);
      dispatch(slice.actions.getBatchSchedulesSuccess(schedulesDict));
      dispatch(
        slice.actions.getBatchContentsSuccess(response.data.batch_contents)
      );
      dispatch(
        slice.actions.getBatchContentsDictSuccess(
          listToDictContent(response.data.batch_contents, schedulesDict)
        )
      );
    } catch (error) {
      console.log(error);
    }
  };
}
