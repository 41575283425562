import { ReactNode } from 'react';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
//
import { Container } from '@material-ui/core';
import SubNav from '../../../components/SubNavStaff';
import { PATH_PROFILES } from '../../../routes/paths';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: '100%',
  paddingTop: theme.spacing(0)
}));
// todo
const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  minHeight: '100%',
  marginTop: theme.spacing(4)
}));

const links = [
  { name: 'All Agents', route: PATH_PROFILES.allAgents },
  { name: 'New Agents', route: PATH_PROFILES.newAMCAgents },
  { name: 'Paused & Broken', route: PATH_PROFILES.pausedAccounts }
  // { name: 'New Marketing Club', route: PATH_PROFILES.newMCAgents }
];
// ----------------------------------------------------------------------

type StaffLayoutProps = {
  children?: ReactNode;
};

export default function ProfilesLayout({ children }: StaffLayoutProps) {
  return (
    <RootStyle>
      <Container maxWidth="xl">
        <SubNav links={links} compact primaryNav={true} />
        <MainStyle>{children}</MainStyle>
      </Container>
    </RootStyle>
  );
}
