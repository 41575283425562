import { motion } from 'framer-motion';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
//
import Logo from './Logo';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------
type LoadingScreenProps = {
  small?: boolean;
  sx?: any;
};

export default function LoadingScreen(props: LoadingScreenProps) {
  const { small, sx = {} } = props;
  const size = small ? 40 : 64;

  return (
    <RootStyle className="loading-spinner" sx={{ ...sx }}>
      <motion.div
        initial={{ rotateY: 0 }}
        animate={{ rotateY: 360 }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          repeatDelay: 1,
          repeat: Infinity
        }}
      >
        <Logo sx={{ height: `${size / 16}rem` }} />
      </motion.div>

      <Box
        component={motion.div}
        animate={{
          scale: [1.2, 1, 1, 1.2, 1.2],
          rotate: [270, 0, 0, 270, 270],
          opacity: [0.25, 1, 1, 1, 0.25],
          borderRadius: ['25%', '25%', '50%', '50%', '25%']
        }}
        transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
        sx={{
          width: `${(size * 1.34) / 16}rem`,
          height: `${(size * 1.34) / 16}rem`,
          borderRadius: '25%',
          position: 'absolute',
          border: (theme) =>
            `solid 0.1875rem ${alpha(theme.palette.primary.dark, 0.24)}`
        }}
      />

      <Box
        component={motion.div}
        animate={{
          scale: [1, 1.2, 1.2, 1, 1],
          rotate: [0, 270, 270, 0, 0],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          borderRadius: ['25%', '25%', '50%', '50%', '25%']
        }}
        transition={{
          ease: 'linear',
          duration: 3.2,
          repeat: Infinity
        }}
        sx={{
          width: `${(size * 2) / 16}rem`,
          height: `${(size * 2) / 16}rem`,
          borderRadius: '25%',
          position: 'absolute',
          border: (theme) =>
            `solid .5rem ${alpha(theme.palette.primary.dark, 0.24)}`
        }}
      />
    </RootStyle>
  );
}
