// ----------------------------------------------------------------------
const calculatedFontSize =
  window.screen.availWidth > 1200
    ? Math.ceil((window.screen.availWidth / 1920) * 16)
    : 16;

export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

/**
 * Convert rems to our custom pixel value
 * @param value The rem value to convert in string format
 * @returns The pixel value in relation to the current base rem pixels
 */
export function remToCustomPx(value: string) {
  return Math.round(parseFloat(value) * calculatedFontSize);
}

/**
 * Convert pixels to our custom rem value
 * @param value Pixels to convert to rem
 * @returns The rem value in relation to the current base rem pixels
 */
export function pxToCustomRem(value: number) {
  return `${value / calculatedFontSize}rem`;
}
