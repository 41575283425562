import jwtDecode from 'jwt-decode';

// This function will not work if user signs in via dashboard
// As they are manually given a token rather than using
// the CustomTokenObtainPairView
export function isCustomer() {
  const token = localStorage.getItem('accessToken');
  if (token) {
    const decoded: any = jwtDecode(token);
    if (decoded.user_type === 'Customer') return true;
  }
  return false;
}

export function isEmployee() {
  const token = localStorage.getItem('accessToken');
  try {
    if (token) {
      const decoded: any = jwtDecode(token);
      if (decoded.user_type === 'Employee') return true;
    }
  } catch (err) {
    // console.error(err);
    // console.trace('Invalid token');
  }
  return false;
}

export function isAMC() {
  const token = localStorage.getItem('accessToken');
  try {
    if (token) {
      const decoded: any = jwtDecode(token);
      if (decoded.subscription_type == 'AMC') return true;
    }
  } catch (err) {
    console.error(err);
  }
  return false;
}
