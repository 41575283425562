import PersonIcon from '@material-ui/icons/Person';
import FilterIcon from '@material-ui/icons/Filter';
import { PATH_PROFILES, PATH_MARKETING } from '../routes/paths';

export function getStaffLinks(feature_flag_marketing: any) {
  return feature_flag_marketing
    ? [
        {
          name: 'Profiles',
          icon: PersonIcon,
          route: PATH_PROFILES.allAgents
        },
        {
          name: 'Campaigns',
          icon: FilterIcon,
          route: PATH_MARKETING.campaigns
        }
      ]
    : [
        {
          name: 'Profiles',
          icon: PersonIcon,
          route: PATH_PROFILES.allAgents
        }
      ];
}
