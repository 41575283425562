// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DOCS = '/docs';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_PROFILES = '/profiles';
const ROOTS_MARKETING = '/marketing';
const ROOTS_WIZARD = '/wizard';
const ROOTS_FIRST_CAMPAIGN = '/first-campaign';
const ROOTS_MARKETING_CLUB_WIZARD = '/get-started';
const ROOTS_OVERVIEW = '/profile';
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  forgotPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  sso: path(ROOTS_AUTH, '/sso'),
  orderForm: path(ROOTS_AUTH, '/order-form'),
  setPassword: path(ROOTS_AUTH, '/set-password/:pk/:token'),
  updateForgotPassword: path(
    ROOTS_AUTH,
    '/update-forgot-password/:email/:token'
  )
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  page404: '/404',
  page500: '/500',
  page401: '/401'
};

export const PATH_PROFILES = {
  root: ROOTS_PROFILES,
  allAgents: path(ROOTS_PROFILES, '/all-agents'),
  newAMCAgents: path(ROOTS_PROFILES, '/new-amc-agents'),
  newMCAgents: path(ROOTS_PROFILES, '/new-mc-agents'),
  user: path(ROOTS_PROFILES, '/:userId'),
  tasks: path(ROOTS_PROFILES, '/coming-soon'),
  nylas_redirect: path(ROOTS_PROFILES, '/nylas-redirect'),
  marketingContent: path(ROOTS_PROFILES, '/marketing-content'),
  marketingTemplates: path(ROOTS_PROFILES, '/marketing-templates'),
  postRecords: path(ROOTS_PROFILES, '/post-records'),
  pausedAccounts: path(ROOTS_PROFILES, '/paused-accounts')
};
export const PATH_MARKETING = {
  root: ROOTS_MARKETING,
  campaigns: path(ROOTS_MARKETING, '/campaigns'),
  marketingTemplates: path(ROOTS_MARKETING, '/content'),
  createNewCampaignContent: path(
    ROOTS_MARKETING,
    '/content/:campaignID/create-new/:schedulerID'
  ),
  postRecords: path(ROOTS_MARKETING, '/post-records'),
  campaignRecords: path(ROOTS_MARKETING, '/campaign-records'),
  postRecordsBulk: path(
    ROOTS_MARKETING,
    '/post-records/bulk/:recordType/:recordID'
  ),
  createNewCampaign: path(ROOTS_MARKETING, '/campaigns/create-new'),
  campaignTemplate: path(
    ROOTS_MARKETING,
    '/campaigns/campaign-template/:campaignID'
  ),
  contentTemplate: path(
    ROOTS_MARKETING,
    '/content/content-template/:contentID'
  ),
  campaignEdit: path(ROOTS_MARKETING, '/campaigns/campaign-edit/:campaignID'),
  campaignClone: path(ROOTS_MARKETING, '/campaigns/campaign-clone/:campaignID'),
  createNewTemplateContent: path(
    ROOTS_MARKETING,
    '/content/:campaignID/create-new-template'
  ),
  contentEdit: path(ROOTS_MARKETING, '/content/content-edit/:contentID/:type'),
  contentClone: path(
    ROOTS_MARKETING,
    '/content/content-clone/:contentID/:type'
  ),
  postNow: path(ROOTS_MARKETING, '/content/post-now/:contentID'),
  postNowCampaign: path(ROOTS_MARKETING, '/campaigns/post-now/:campaignID'),
  campaignSetupBatch: path(
    ROOTS_MARKETING,
    '/campaigns/:campaignId/batch-setup'
  ),
  campaignLaunchedBatch: path(
    ROOTS_MARKETING,
    '/campaigns/post-record-batch/:recordType/:recordID'
  ),
  campaignPostRecordBatchSingle: path(
    ROOTS_MARKETING,
    '/campaigns/post-record-batch-single/:batchContentID'
  )
};

export const PATH_OVERVIEW = {
  inner_root: ROOTS_OVERVIEW,
  root: path(ROOTS_OVERVIEW, '/:userId'),
  overview: path(ROOTS_OVERVIEW, '/:userId'),
  contacts: path(ROOTS_OVERVIEW, '/:userId/contacts'),
  tasks: path(ROOTS_OVERVIEW, '/:userId/tasks'),
  postHistory: path(ROOTS_OVERVIEW, '/:userId/postHistory'),
  analytics: path(ROOTS_OVERVIEW, '/:userId/analytics'),
  content: path(ROOTS_OVERVIEW, '/:userId/content'),
  createPost: path(ROOTS_OVERVIEW, '/:userId/createPost'),
  feed: path(ROOTS_OVERVIEW, '/:userId/feed'),
  infusionsoft: path(ROOTS_OVERVIEW, '/:userId/infusionsoft'),
  contact_sync: path(ROOTS_OVERVIEW, '/:userId/contact-sync'),
  hotsheet: path(ROOTS_OVERVIEW, '/:userId/hotsheet'),
  allCampaigns: path(ROOTS_OVERVIEW, '/:userId/campaigns/browse-campaigns'),
  email_text_settings: path(ROOTS_OVERVIEW, '/:userId/settings'),
  account: path(ROOTS_OVERVIEW, '/:userId/account'),
  posts: path(ROOTS_OVERVIEW, '/:userId/posts'),
  yourCampaigns: path(ROOTS_OVERVIEW, '/:userId/campaigns/your-campaigns'),
  campaignDefault: path(ROOTS_OVERVIEW, '/:userId/campaign/:campaignId'),
  campaignPreview: path(
    ROOTS_OVERVIEW,
    '/:userId/:campaignId/campaign-preview'
  ),
  campaignDirector: path(
    ROOTS_OVERVIEW,
    '/:userId/campaign/:campaignId/launched'
  ),
  campaignSetup: path(ROOTS_OVERVIEW, '/:userId/campaign/:campaignId/setup')
};

export const PATH_HOME = {
  cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
  purchase: 'https://material-ui.com/store/items/minimal-dashboard/',
  components: '/components',
  dashboard: ROOTS_DASHBOARD,
  agent: '/',
  staff: PATH_PROFILES.allAgents,
  agentLogout: 'https://dashboard.thepaperlessagent.com/logout'
};

export const PATH_AGENTS = {
  root: PATH_HOME.agent,
  resources: `${PATH_HOME.agent}#resources`,
  tools: `${PATH_HOME.agent}#tools`,
  action: '/action',
  calendar: '/calendar',
  reports: '/reports',
  club: 'https://dashboard.thepaperlessagent.com/dashboard',
  contacts: '/contacts',
  contact_sync: '/contacts/sync',
  hotsheet: '/hotsheet',
  account: '/account',
  posts: '/posts',
  allCampaigns: '/campaigns/browse-campaigns',
  yourCampaigns: '/campaigns/your-campaigns',
  campaignPreview: '/:campaignId/campaign-preview',
  campaignDefault: '/campaign/:campaignId',
  campaignDirector: '/campaign/:campaignId/launched',
  campaignSetup: '/campaign/:campaignId/setup'
};

export const PATH_WIZARD = {
  root: ROOTS_WIZARD,
  welcome: path(ROOTS_WIZARD, '/welcome'),
  schedule: path(ROOTS_WIZARD, '/schedule'),
  personalizeYourMarketing: path(ROOTS_WIZARD, '/personalize-your-marketing'),
  setupContactInfo: path(ROOTS_WIZARD, '/setup-contact-info'),
  businessAndBrokerage: path(ROOTS_WIZARD, '/business-and-brokerage'),
  helpUsGetToKnowYou: path(ROOTS_WIZARD, '/help-us-get-to-know-you'),
  letsGo: path(ROOTS_WIZARD, '/lets-go'),
  setupAgentID: path(ROOTS_WIZARD, '/setup-agentID')
};

export const PATH_MARKETING_CLUB_WIZARD = {
  root: ROOTS_MARKETING_CLUB_WIZARD,
  welcome: path(ROOTS_MARKETING_CLUB_WIZARD, '/welcome'),
  setupAgentID: path(ROOTS_MARKETING_CLUB_WIZARD, '/setup-agentID'),
  setupContactInfo: path(ROOTS_MARKETING_CLUB_WIZARD, '/setup-contact-info'),
  businessAndBrokerage: path(
    ROOTS_MARKETING_CLUB_WIZARD,
    '/business-and-brokerage'
  ),
  helpUsGetToKnowYou: path(
    ROOTS_MARKETING_CLUB_WIZARD,
    '/help-us-get-to-know-you'
  ),
  letsGo: path(ROOTS_MARKETING_CLUB_WIZARD, '/lets-go')
};

export const PATH_MARKETING_CLUB_WIZARDPATH_DOCS = {
  root: ROOTS_DOCS,
  support: path(ROOTS_DOCS, '/support'),
  changelog: path(ROOTS_DOCS, '/changelog')
};

export const PATH_FIRST_CAMPAIGN_WIZARD = {
  root: ROOTS_FIRST_CAMPAIGN,
  callToAction: path(ROOTS_FIRST_CAMPAIGN, '/pick-a-campaign'),
  uploadContacts: path(ROOTS_FIRST_CAMPAIGN, '/upload-contacts/:campaignId'),
  connectSocials: path(ROOTS_FIRST_CAMPAIGN, '/connect-socials/:campaignId'),
  setupAgentIDSite: path(
    ROOTS_FIRST_CAMPAIGN,
    '/setup-agentid-site/:campaignId'
  ),
  visitAgentIDSite: path(
    ROOTS_FIRST_CAMPAIGN,
    '/visit-agentid-site/:campaignId'
  ),
  personalizeYourMarketing: path(
    ROOTS_FIRST_CAMPAIGN,
    '/personalize-your-marketing/:campaignId'
  )
};

export const PATH_FIRST_CAMPAIGN_WIZARD_EMPLOYEE = {
  root: ROOTS_OVERVIEW,
  callToAction: path(
    ROOTS_OVERVIEW,
    `/:userId${ROOTS_FIRST_CAMPAIGN}/pick-a-campaign`
  ),
  uploadContacts: path(
    ROOTS_OVERVIEW,
    `/:userId${ROOTS_FIRST_CAMPAIGN}/upload-contacts/:campaignId`
  ),
  connectSocials: path(
    ROOTS_OVERVIEW,
    `/:userId${ROOTS_FIRST_CAMPAIGN}/connect-socials/:campaignId`
  ),
  setupAgentIDSite: path(
    ROOTS_OVERVIEW,
    `/:userId${ROOTS_FIRST_CAMPAIGN}/setup-agentid-site/:campaignId`
  ),
  visitAgentIDSite: path(
    ROOTS_OVERVIEW,
    `/:userId${ROOTS_FIRST_CAMPAIGN}/visit-agentid-site/:campaignId`
  ),
  personalizeYourMarketing: path(
    ROOTS_OVERVIEW,
    `/:userId${ROOTS_FIRST_CAMPAIGN}/personalize-your-marketing/:campaignId`
  )
};
