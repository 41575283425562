import { ReactNode } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Appcues, Loader } from 'appcues-loader';
import useAuth from '../hooks/useAuth';
import { PATH_AGENTS, PATH_PROFILES } from '../routes/paths';
import LoadingScreen from '../components/LoadingScreen';
import Footer from '../components/Footer';

// ----------------------------------------------------------------------

type AuthProtectProps = {
  children: ReactNode;
};

export default function AuthProtect({ children }: AuthProtectProps) {
  const {
    isLoading,
    isAuthenticated,
    user,
    urlParams,
    displayedUserDetails
  } = useAuth();
  const history = useHistory();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <>{children}</>;
  }

  if (user.is_employee) {
    try {
      const redirect = urlParams.get('redirect');
      if (
        redirect &&
        redirect !== '' &&
        redirect !== '/' &&
        redirect !== '/auth/login' &&
        redirect !== '/auth/sso'
      ) {
        history.push(redirect);
        return <Redirect to={redirect} />;
      }
      return <Redirect to={PATH_PROFILES.allAgents} />;
    } catch (err) {
      return <Redirect to={PATH_PROFILES.allAgents} />;
    }
  }
  if (user.is_customer) {
    try {
      const redirect = urlParams.get('redirect');
      if (
        redirect &&
        redirect !== '' &&
        redirect !== '/' &&
        redirect !== '/auth/login' &&
        window.location.pathname !== '/auth/sso'
      ) {
        return <Redirect to={redirect} />;
      }
      if (window.location.pathname !== '/auth/sso') {
        return <Redirect to={PATH_AGENTS.root} />;
      }
    } catch (err) {
      return <Redirect to={PATH_AGENTS.root} />;
    }
  }

  // Set up the new Appcues loader
  if (displayedUserDetails && displayedUserDetails.id && !isLoading) {
    const {
      appcues_uuid,
      first_name,
      last_name,
      email,
      subscription_type,
      imported_existing_user,
      infusionsoft_id
    } = displayedUserDetails;
    const loader = new Loader('135939');
    loader.load().then((appcues: Appcues) => {
      appcues.page();
      appcues.identify(appcues_uuid, {
        first_name,
        last_name,
        email,
        subscription_type,
        imported_existing_user,
        ...(infusionsoft_id !== null ? { infusionsoft_id } : {}) // This will spread the infusionsoft_id into main object if it exists, or nothing if it doesn't
      });
    });
  }

  return (
    <>
      {children}
      <Footer />
    </>
  );
}
