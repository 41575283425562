// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import { MAvatarProps } from './@material-extend/MAvatar';

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { displayedUserDetails } = useAuth();
  // TODO: Figure out why displayedUserDetails has multiple different shapes
  const avatar =
    displayedUserDetails?.avatar || displayedUserDetails?.avatars?.[0] || '';
  return <MAvatar src={avatar} {...other} />;
}
