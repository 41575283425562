import { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { Appcues, Loader } from 'appcues-loader';
import useAuth from '../hooks/useAuth';
import { PATH_AUTH, PATH_HOME } from '../routes/paths';
import LoadingScreen from '../components/LoadingScreen';
import Footer from '../components/Footer';

type AuthProtectProps = {
  children: ReactNode;
};

export default function AgentProtect({ children }: AuthProtectProps) {
  const { isLoading, isAuthenticated, user, displayedUserDetails } = useAuth();
  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Redirect to={PATH_AUTH.login} />;
  }

  if (user.is_employee) {
    return <Redirect to={PATH_HOME.staff} />;
  }

  if (!displayedUserDetails?.redirect_to_wizard) {
    return <Redirect to={PATH_HOME.agent} />;
  }
  if (displayedUserDetails?.subscription_type !== 'AMC') {
    return <Redirect to={PATH_HOME.agent} />;
  }

  // Set up the new Appcues loader
  if (displayedUserDetails && displayedUserDetails.id && !isLoading) {
    const {
      appcues_uuid,
      first_name,
      last_name,
      email,
      subscription_type,
      imported_existing_user,
      infusionsoft_id
    } = displayedUserDetails;
    const loader = new Loader('135939');
    loader.load().then((appcues: Appcues) => {
      appcues.page();
      appcues.identify(appcues_uuid, {
        first_name,
        last_name,
        email,
        subscription_type,
        imported_existing_user,
        ...(infusionsoft_id !== null ? { infusionsoft_id } : {}) // This will spread the infusionsoft_id into main object if it exists, or nothing if it doesn't
      });
    });
  }

  return (
    <>
      {children}
      <Footer />
    </>
  );
}
