import { map, reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import { isToday } from 'date-fns';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  contents: {},
  isOpenModal: false,
  selectedContentId: null,
  selectedRange: null,
  isDayContentsModalOpen: false
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getContentsSuccess(state, action) {
      state.isLoading = false;
      state.contents = action.payload;
    },

    // CREATE EVENT
    createContentSuccess(state: any, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.contents = [...state.contents, newEvent];
    },

    // UPDATE EVENT
    updateContentSuccess(state: any, action) {
      const event = action.payload;
      const updateContent = map(state.contents, (_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.contents = updateContent;
    },

    updateSingleBatchScheduleSuccess(state: any, action) {
      const newSchedule = action.payload;
      const newSchedules = { ...state.batchSchedules };
      newSchedules[newSchedule.template_content_pk] = newSchedule;
      state.batchSchedules = newSchedules;
    },

    // DELETE EVENT
    deleteContentSuccess(state: any, action) {
      const { eventId } = action.payload;
      const deleteEvent = reject(state.contents, { id: eventId });

      state.isLoading = false;
      state.contents = deleteEvent;
    },

    // SELECT EVENT
    selectContent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedContentId = eventId;
    },

    // SELECT RANGE
    selectRange(state: any, action) {
      const { start, end } = action.payload;
      // state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedContentId = null;
      state.selectedRange = null;
    },

    // Open Day Events Modal
    openDayContentsModal(state) {
      state.isDayContentsModalOpen = true;
    },

    // Close Day Events Modal
    closeDayContentsModal(state) {
      state.isDayContentsModalOpen = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { closeModal, selectContent } = slice.actions;

// ----------------------------------------------------------------------

export function getEvents() {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/calendar/events'); // We can change this function to get all of a customers events by default. Just need an endpoint
      dispatch(slice.actions.getContentsSuccess(response.data.events));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setContentsRedux(contents: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const newContents: any = {};
      let index = 0;
      Object.keys(contents).forEach((key: any) => {
        const content: any = contents[key];
        const newContent = {
          key: index,
          start:
            typeof content.scheduled_for === 'string'
              ? content.scheduled_for
              : content.scheduled_for.facebook !== ''
              ? content.scheduled_for.facebook
              : content.scheduled_for.instagram,
          ...content
        };
        newContents[key] = newContent;
        index += 1;
      });

      dispatch(slice.actions.getContentsSuccess(newContents));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createEvent(newEvent: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/calendar/events/new', newEvent);
      dispatch(slice.actions.createContentSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateContent(
  eventId: any,
  updateContent: any,
  showToast: Function
) {
  const dateJS = new Date(updateContent.start);
  let dateISO = dateJS.toISOString();
  if (isToday(dateJS)) {
    dateISO = new Date().toISOString();
  }
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/marketing/set_content_scheduled_for/${eventId}/${dateISO}/`
      );

      const { content } = response.data;
      content.start = content.scheduled_for;
      dispatch(slice.actions.updateContentSuccess(content));
      showToast('success');
    } catch (error) {
      console.log('the error from updateContent is ', error);
      dispatch(slice.actions.hasError(error));
      showToast('failure');
    }
  };
}
// ----------------------------------------------------------------------

export function updateContentBatch(
  eventId: any,
  updateContent: any,
  batchCampaignID: any,
  showToast: any
) {
  const dateJS = new Date(updateContent.start);
  const dateISO = dateJS.toISOString();
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      // we're using the personalized content to display to the user in the calendar
      // but we can find the batch content from the personalized content and batch campaign
      const response = await axios.get(
        `/marketing/set_content_scheduled_for/${eventId}/${dateISO}/?batch_campaign_id=${batchCampaignID}`
      );
      const { content, new_schedule } = response.data;
      content.start = content.scheduled_for;
      dispatch(slice.actions.updateContentSuccess(content));
      dispatch(slice.actions.updateSingleBatchScheduleSuccess(new_schedule));
      showToast('success');
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      showToast('failure');
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEvent(eventId: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/calendar/events/delete', { eventId });
      dispatch(slice.actions.deleteContentSuccess({ eventId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function selectRange(start: any, end: any) {
  return async (dispatch: any) => {
    dispatch(
      slice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime()
      })
    );
  };
}

// ----------------------------------------------------------------------

export function openModal() {
  return async (dispatch: any) => {
    dispatch(slice.actions.openModal());
  };
}

// ----------------------------------------------------------------------

export function setOpenModal(newState: any) {
  return (dispatch: any) => {
    if (newState) {
      dispatch(slice.actions.openModal());
    } else {
      dispatch(slice.actions.closeModal());
    }
  };
}

// ----------------------------------------------------------------------

export function setDayContentsModalOpen(newState: any) {
  return (dispatch: any) => {
    if (newState) {
      dispatch(slice.actions.openDayContentsModal());
    } else {
      dispatch(slice.actions.closeDayContentsModal());
    }
  };
}
