import { Link as UnstyledLink } from 'react-router-dom';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Logo from './Logo';

const Link = styled(UnstyledLink)(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  p: {
    marginLeft: '0.625rem'
  },
  svg: {
    marginLeft: '0.625rem'
  },
  textDecoration: 'none'
}));

type Props = {
  to: string;
};
export default function LogoLink({ to }: Props) {
  return (
    <Link to={to}>
      <Logo />
    </Link>
  );
}
