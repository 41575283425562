// react
import { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useDispatch, useSelector } from 'react-redux';
// material
import {
  experimentalStyled as styled,
  useTheme,
  makeStyles
} from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Link,
  Container,
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
  Drawer,
  IconButton
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
// components
import PersonOutline from '@material-ui/icons/PersonOutline';
import { isEmployee } from '../utils/getUserType';
import LogoLink from './LogoLink';
import { PATH_HOME } from '../routes/paths';
import AccountPopover from '../layouts/dashboard/AccountPopover';
import {
  getDisableExternalActionItemLink,
  getTriggerAPICall,
  getActionItemsLink,
  getInitialTimeActionItemClick
} from '../redux/slices/displayedUser';
// redux
import { RootState } from '../redux/store';
import { triggerInteractionUpdateList } from '../redux/slices/todoList';
import useAuth from '../hooks/useAuth';
// ----------------------------------------------------------------------

type LinkList = {
  route: string;
  external?: boolean;
  name: string;
  icon?: any;
  routesToAssignToParent?: Array<string>;
};

type DashboardNavbarProps = {
  links: LinkList[];
  compact?: boolean;
};

const CornerMenu = ({
  desktop,
  onlyRunSettingAppcuesOnceRef
}: {
  desktop: boolean;
  onlyRunSettingAppcuesOnceRef: any;
}) => (
  <Box
    display="flex"
    justifyContent="flex-end"
    mt="auto"
    mb="auto"
    alignItems="center"
    flexGrow={desktop ? 1 : 0}
  >
    <AccountPopover
      onlyRunSettingAppcuesOnceRef={onlyRunSettingAppcuesOnceRef}
    />
  </Box>
);

export default function DashboardNavbar({
  links,
  compact
}: DashboardNavbarProps) {
  const dispatch = useDispatch();
  const { user, displayedUserDetails } = useAuth();
  const onlyRunSettingAppcuesOnceRef = useRef(true);
  const LinkWrapperDesktop = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '0.625rem',
    [theme.breakpoints.up('md')]: {
      marginRight: '0.9375rem'
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: '1.25rem'
    },
    [theme.breakpoints.up('xl')]: {
      marginRight: '1.875rem'
    }
  }));
  const LogoWrap = styled(Box)(() => ({
    width: '3.75rem',
    img: {
      margin: 'auto'
    }
  }));
  const LogoWrapDrawer = styled(Box)(() => ({
    width: '3.75rem',
    img: {
      marginTop: '0.9375rem',
      marginLeft: '1.125rem',
      marginBottom: '0.9375rem'
    }
  }));
  const LogoWrapMobile = styled(Box)(() => ({
    width: '3.75rem',
    img: {
      marginLeft: isEmployee() ? '0rem' : '1.2rem',
      marginRight: '0px',
      paddingLeft: '0px',
      paddingRight: '0px'
    }
  }));
  const useStyles = makeStyles({
    drawer: {
      width: '70%'
    }
  });
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const [expanded, setExpanded] = useState<boolean>(false);
  const [sidebarExpanded, setSidebarExpanded] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { actionItemsLink } = useSelector((state: RootState) => state.user);
  const handleDrawerListClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    name: string,
    route: string
  ) => {
    setSelectedIndex(index);
    setSidebarExpanded(false);
    if (name === '1-Min Video') {
      window.open(route, '_self');
      const testLinkForRunLink = route.match('run-link');
      if (testLinkForRunLink && testLinkForRunLink.index) {
        dispatch(getDisableExternalActionItemLink(true));
        dispatch(getTriggerAPICall(true));
        dispatch(getActionItemsLink('#'));
        dispatch(getInitialTimeActionItemClick(Math.round(Date.now() / 1000)));
      }
    }
  };
  const handleMenuButtonClick = () => (
    event: React.MouseEvent<HTMLElement>
  ) => (event.preventDefault(), setSidebarExpanded(true));
  const URLpathname = useLocation().pathname;
  const [loc, setLoc] = useState<string>('');
  /* 
  there's an issue with algining the url path with a selected desktop and 
  drawer link, because sometimes many links (like the intake form) all need to 
  be routed to 'complete AMC form'. So this useEffect assigns the current URL 
  to a selected index in the links array
  */
  useEffect(() => {
    setLoc(URLpathname);
    for (let i = 0; i < links.length; i++) {
      if (links[i].route === URLpathname) {
        setSelectedIndex(i);
        // some of the link arrays are given routesToAssignParent
        // to signify that all those routes need to have a parent
        // link assigned as 'selected'
      } else if (URLpathname === '/account') {
        // Remove selected index if on account page
        setSelectedIndex(-1);
      } else if (
        URLpathname.split('/')[1] === 'marketing' &&
        links[i].route.split('/')[1] === 'marketing'
      ) {
        setSelectedIndex(i);
      } else if (
        (URLpathname.split('/')[1].includes('campaign') &&
          links[i].route.split('/')[1] === 'campaigns') ||
        (URLpathname.split('/')[1].includes('posts') &&
          links[i].route.split('/')[1] === 'campaigns')
      ) {
        setSelectedIndex(i);
      } else if (
        URLpathname.split('/')[1].includes('contacts') &&
        links[i].route.split('/')[1] === 'contacts'
      ) {
        setSelectedIndex(i);
      } else if (
        URLpathname.split('/')[1].includes('hotsheet') &&
        links[i].route.split('/')[1] === 'hotsheet'
      ) {
        setSelectedIndex(i);
      } else {
        const optionalArray = links[i].routesToAssignToParent || [];
        for (let j = 0; j < optionalArray.length; j++) {
          if (optionalArray[j] === URLpathname) {
            setSelectedIndex(i);
          }
        }
      }
    }
  }, [URLpathname, links]);
  useEffect(() => {
    isNotMobile ? setExpanded(true) : setExpanded(false);
  }, [isNotMobile]);
  const RootStyle = styled(Box)(({ theme }) => ({
    marginTop: '0.9375rem',
    borderRadius: '1rem',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    minHeight: '4.5rem',
    p: {
      margin: '0.625rem 0.625rem'
    },
    svg: {
      color: theme.palette.text.secondary
    },
    'p, svg': {
      '&.selected': {
        color: theme.palette.text.primary,
        '&:hover': {
          color: theme.palette.text.primary
        }
      },
      '&:hover': {
        color: theme.palette.text.secondary
      }
    },
    a: {
      '&.selected': {
        borderBottom: `${theme.palette.primary.main} solid 0.1875rem`
      },
      '&:hover': {
        textDecoration: 'none'
      }
    }
  }));

  const MobileStyle = styled(Box)(({ theme }) => ({
    marginTop: '0.625rem',
    borderRadius: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: compact ? 'fit-content' : '100%',
    minHeight: '2.5rem',
    p: {
      padding: '0.625rem 0px 0px 0.625rem'
    },
    svg: {
      color: theme.palette.text.secondary
    },
    'p, svg': {
      '&.selected': {
        color: theme.palette.text.primary,
        '&:hover': {
          color: theme.palette.text.primary
        }
      },
      '&:hover': {
        color: theme.palette.text.secondary
      }
    },
    a: {
      '&.selected': {
        borderBottom: `${theme.palette.primary.main} solid 0.1875rem`
      },
      '&:hover': {
        textDecoration: 'none'
      }
    }
  }));
  const ListDrawerStyle = styled(List)(({ theme }) => ({
    root: {
      marginTop: '1.25rem'
    },
    svg: {
      color: theme.palette.text.secondary
    },
    'p, svg': {
      '&.selected': {
        color: theme.palette.text.primary,
        '&:hover': {
          color: theme.palette.text.primary
        }
      },
      '&:hover': {
        color: theme.palette.text.secondary
      }
    },
    a: {
      '&.selected': {
        borderBottom: `${theme.palette.primary.main} solid 0.1875rem`
      },
      '&:hover': {
        textDecoration: 'none'
      }
    }
  }));
  //  check if the link is 1-Min Video, and disable the link if it's a run link
  const checkActionItems = (name: string, route: string) => {
    if (name === '1-Min Video') {
      window.open(route, '_self');
    }
  };
  const mobileLinks = (
    <MobileStyle>
      <Box display="flex" justifyContent="space-evenly" mt="auto" mb="auto">
        <IconButton
          onClick={handleMenuButtonClick()}
          style={{ padding: '0px 0px 0px 0px', margin: '0px 0px 0px 0px' }}
        >
          <MenuRoundedIcon style={{ width: '2.5rem', height: '2.5rem' }} />
        </IconButton>
      </Box>
      <Box display="flex" justifyContent="space-evenly" mt="auto" mb="auto">
        <LogoWrapMobile>
          <LogoLink to={PATH_HOME.staff} />
        </LogoWrapMobile>
      </Box>
      <CornerMenu
        desktop={false}
        onlyRunSettingAppcuesOnceRef={onlyRunSettingAppcuesOnceRef}
      />
    </MobileStyle>
  );

  // Alter the link typography for better 3col
  const link_typography_sx = (link: any) => {
    const { name } = link;
    const hide_names = ['Help'];

    const output: any = {
      lineHeight: '1'
    };

    if (!isDesktop) {
      output.width = 'min-content';
      output.marginRight = '0 !important';
      output.marginLeft = '0.565rem !important';
      if (hide_names.includes(name)) {
        output.display = 'none';
      }
    }

    return output;
  };

  const desktopLinks = (
    <RootStyle>
      <Box
        sx={{
          paddingRight: isDesktop ? theme.spacing(3) : '0.625rem'
        }}
      >
        <LogoWrap>
          <LogoLink to={PATH_HOME.staff} />
        </LogoWrap>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        marginLeft="0px"
        paddingLeft="0px"
      >
        {links.map((link, index) =>
          link.route ? (
            <LinkWrapperDesktop key={link.name}>
              {link.name === '1-Min Video' ? (
                <a href="#" style={{ textDecoration: 'none' }}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    onClick={() => checkActionItems(link.name, link.route)}
                  >
                    {link.icon && (
                      <PersonOutline
                        sx={{ mt: '0.1875rem' }}
                        className={index === selectedIndex ? 'selected' : ''}
                      />
                    )}
                    <Typography
                      variant="body1"
                      className={index === selectedIndex ? 'selected' : ''}
                      color="GrayText"
                    >
                      {link.name}
                    </Typography>
                  </Box>
                </a>
              ) : link.external ? (
                <Link
                  to={{ pathname: link.route }}
                  className={index === selectedIndex ? 'selected' : ''}
                  key={link.name}
                  component={RouterLink}
                  onClick={() => {
                    if (link.name === 'Training' && !user.is_employee) {
                      dispatch(
                        triggerInteractionUpdateList(
                          'todo/create_interaction/',
                          'has_registered_advanced_training',
                          URLpathname,
                          displayedUserDetails,
                          user
                        )
                      );
                    }
                  }}
                  target="_blank"
                  // onClick={checkActionItems(link.name, link.route)}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    //  onClick={() => checkActionItems(link.name, actionItemsLink)}
                  >
                    {link.icon && (
                      <link.icon
                        className={index === selectedIndex ? 'selected' : ''}
                        sx={{
                          mt: '0.1875rem'
                        }}
                      />
                    )}
                    <Typography
                      variant="body1"
                      className={index === selectedIndex ? 'selected' : ''}
                      color="GrayText"
                      sx={{ ...link_typography_sx(link) }}
                    >
                      {link.name}
                    </Typography>
                  </Box>
                </Link>
              ) : (
                <Link
                  smooth
                  to={link.route}
                  className={index === selectedIndex ? 'selected' : ''}
                  key={link.name}
                  component={HashLink}
                  onClick={() => {
                    if (link.name === 'Training' && !user.is_employee) {
                      dispatch(
                        triggerInteractionUpdateList(
                          'todo/create_interaction/',
                          'has_registered_advanced_training',
                          URLpathname,
                          displayedUserDetails,
                          user
                        )
                      );
                    }
                  }}
                  // onClick={checkActionItems(link.name, link.route)}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    //  onClick={() => checkActionItems(link.name, actionItemsLink)}
                  >
                    {link.icon && (
                      <link.icon
                        className={index === selectedIndex ? 'selected' : ''}
                        sx={{
                          mt: '0.1875rem'
                        }}
                      />
                    )}
                    <Typography
                      variant="body1"
                      className={index === selectedIndex ? 'selected' : ''}
                      color="GrayText"
                      sx={{ ...link_typography_sx(link) }}
                    >
                      {link.name}
                    </Typography>
                  </Box>
                </Link>
              )}
            </LinkWrapperDesktop>
          ) : (
            ''
          )
        )}
      </Box>
      <CornerMenu
        desktop
        onlyRunSettingAppcuesOnceRef={onlyRunSettingAppcuesOnceRef}
      />
    </RootStyle>
  );
  return (
    <Container maxWidth="xl">
      {expanded ? desktopLinks : mobileLinks}
      <Drawer
        variant="temporary"
        anchor="left"
        open={sidebarExpanded}
        onClose={() => setSidebarExpanded(false)}
        className={classes.drawer}
        ModalProps={{
          keepMounted: true
        }}
        classes={{ paper: classes.drawer }}
      >
        <LogoWrapDrawer>
          <LogoLink to={PATH_HOME.staff} />
        </LogoWrapDrawer>
        <ListDrawerStyle>
          <List>
            {links.map((link, index) =>
              link.route ? (
                link.external ? (
                  <Link
                    to={
                      link.external || link.name === '1-Min Video'
                        ? { pathname: link.route }
                        : link.route
                    }
                    className={link.route === loc ? 'selected' : ''}
                    key={link.name}
                    component={RouterLink}
                    target={
                      link.external && link.name !== '1-Min Video'
                        ? '_blank'
                        : link.name === '1-Min Video'
                        ? '_self'
                        : undefined
                    }
                  >
                    <ListItem
                      style={{
                        paddingTop: '1.25rem',
                        paddingBottom: '1.25rem'
                      }}
                      selected={selectedIndex === index}
                      onClick={(event) =>
                        handleDrawerListClick(
                          event,
                          index,
                          link.name,
                          link.route
                        )
                      }
                    >
                      <ListItemIcon>
                        {link.icon && (
                          <link.icon
                            className={
                              index === selectedIndex ? 'selected' : ''
                            }
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="body1"
                          className={index === selectedIndex ? 'selected' : ''}
                          color="GrayText"
                        >
                          {link.name}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </Link>
                ) : (
                  <Link
                    smooth
                    to={
                      link.external || link.name === '1-Min Video'
                        ? { pathname: link.route }
                        : link.route
                    }
                    className={link.route === loc ? 'selected' : ''}
                    key={link.name}
                    component={HashLink}
                    target={
                      link.external && link.name !== '1-Min Video'
                        ? '_blank'
                        : link.name === '1-Min Video'
                        ? '_self'
                        : undefined
                    }
                  >
                    <ListItem
                      style={{
                        paddingTop: '1.25rem',
                        paddingBottom: '1.25rem'
                      }}
                      selected={selectedIndex === index}
                      onClick={(event) =>
                        handleDrawerListClick(
                          event,
                          index,
                          link.name,
                          link.route
                        )
                      }
                    >
                      <ListItemIcon>
                        {link.icon && (
                          <link.icon
                            className={
                              index === selectedIndex ? 'selected' : ''
                            }
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="body1"
                          className={index === selectedIndex ? 'selected' : ''}
                          color="GrayText"
                        >
                          {link.name}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </Link>
                )
              ) : (
                ''
              )
            )}
          </List>
        </ListDrawerStyle>
      </Drawer>
    </Container>
  );
}
