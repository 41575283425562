//  import { lazy} from 'react';
import { Redirect } from 'react-router';
import { PATH_MARKETING_CLUB_WIZARD } from './paths';
import WizardMarketingClubLayout from '../layouts/wizardboard/marketingClubIndex';
import WizardMarketingClubGuard from '../guards/WizardMarketingClubGuard';
import { lazyWithRetry } from './utils';
// ----------------------------------------------------------------------

const WizardMarketingClubRoutes = {
  path: PATH_MARKETING_CLUB_WIZARD.root,
  guard: WizardMarketingClubGuard,
  layout: WizardMarketingClubLayout,
  routes: [
    // ----------------------------------------------------------------------

    // WIZARD OVERVIEW
    {
      exact: true,
      path: PATH_MARKETING_CLUB_WIZARD.welcome,
      component: lazyWithRetry(
        () => import('../components/wizard/marketing_club/Welcome')
      )
    },
    {
      exact: true,
      path: PATH_MARKETING_CLUB_WIZARD.setupAgentID,
      component: lazyWithRetry(
        () => import('../components/wizard/marketing_club/SetupAgentID')
      )
    },
    {
      exact: true,
      path: PATH_MARKETING_CLUB_WIZARD.letsGo,
      component: lazyWithRetry(
        () => import('../components/wizard/marketing_club/LetsGo')
      )
    },
    // ----------------------------------------------------------------------

    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default WizardMarketingClubRoutes;
