import { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Container } from '@material-ui/core';
import CalendarToday from '@material-ui/icons/CalendarToday';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import BarChartIcon from '@material-ui/icons/BarChart';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ConstructionIcon from '@material-ui/icons/Construction';
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';

import { PATH_AGENTS, PATH_FIRST_CAMPAIGN_WIZARD } from '../../routes/paths';
import DashboardNavbar from '../../components/SubNav';
import useAuth from '../../hooks/useAuth';

import WizardContext from '../../components/wizard/WizardContext';
import LoadingScreen from '../../components/LoadingScreen';
import Page from '../../components/Page';

interface FirstTimeCampaignPages {
  0: String;
  1: String;
  2: String;
  3: String;
}

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: '100%',
  overflow: 'hidden',
  width: '100%',
  paddingBottom: theme.spacing(6),
  '--xPad': theme.spacing(5),
  '--yPad': theme.spacing(2),

  [theme.breakpoints.down('sm')]: {
    '--xPad': theme.spacing(3),
    '--yPad': theme.spacing(2)
  }
}));

const MainWizardContent = styled('div')(() => ({
  flexGrow: 1,
  overflow: 'hidden',
  minHeight: '100%'
}));

const LoadingWrapper = styled(Box)(() => ({
  width: '100%',
  height: '20rem',
  '& .loading-spinner': {
    minHeight: '100vh',
    transform: 'translateY(-5rem)'
  }
}));

const PaddedContainer = styled(Container)(() => ({
  marginTop: '20px'
}));

export const WizCardContent = styled(Box)(() => ({
  padding: 'var(--yPad) var(--xPad)'
}));

type WizardLayoutProps = {
  children?: ReactNode;
};

export default function FirstCampaignWizardLayout({
  children
}: WizardLayoutProps) {
  const history = useHistory();
  const { displayedUserDetails } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [links, setLinks] = useState<any>([]);
  const [ssoLink, setSSOLink] = useState('');
  const firstCamapaignFormIndex =
    displayedUserDetails?.first_time_campaign_index;
  const currentRouteValues = window.location.pathname.split('/');
  const currentPageName = currentRouteValues[2];
  const camapaignId = currentRouteValues[3];
  const pickACampaign = PATH_FIRST_CAMPAIGN_WIZARD.callToAction.split('/')[2];

  const pageIndexMap: FirstTimeCampaignPages = {
    0: 'upload-contacts',
    1: 'connect-socials',
    2: 'setup-agentid-site',
    3: 'visit-agentid-site'
  };

  const customersCurrentPageName =
    pageIndexMap[firstCamapaignFormIndex as keyof FirstTimeCampaignPages];

  if (currentPageName !== pickACampaign) {
    if (
      loading &&
      firstCamapaignFormIndex &&
      currentPageName !== customersCurrentPageName &&
      Object.values(pageIndexMap).includes(currentPageName)
    ) {
      history.push(
        `/first-campaign/${customersCurrentPageName}/${camapaignId}`
      );
    }

    // Get the highest index value from the object
    const keys = Object.keys(pageIndexMap);
    const numKeys = keys
      .map((value) => {
        const output = Number(value);
        if (isNaN(output)) {
          return undefined;
        }
        return output;
      })
      .filter((value) => typeof value !== typeof undefined) as number[];
    const maxIndex = Math.max(...numKeys);
    // In case first_time_campaign_complete doesn't get set for some reason I'm checking for an out of bounds index
    const overIndex = (firstCamapaignFormIndex ?? 0) > maxIndex;

    if (displayedUserDetails?.first_time_campaign_complete || overIndex) {
      history.push(`/campaign/${camapaignId}`);
    }
  }

  const overview_link = {
    name: 'Home',
    route: PATH_AGENTS.root,
    icon: HomeIcon,
    external: false
  };
  const tools_link = {
    name: 'Tools & Resources',
    route: PATH_AGENTS.tools,
    icon: ConstructionIcon,
    external: false
  };
  const club_classic_link = {
    name: 'Club Classic',
    route: ssoLink,
    icon: DashboardIcon,
    external: true
  };
  const help_link = {
    name: 'Help',
    route:
      'https://thepaperlessagent.freshdesk.com/support/solutions/articles/14000143812-getting-started-with-marketing-club-v2',
    icon: HelpOutlineIcon,
    external: true
  };
  const campaignsLink = {
    name: 'Campaigns',
    route: PATH_AGENTS.allCampaigns,
    icon: CalendarToday,
    external: false
  };
  const contacts_link = {
    name: 'Contacts',
    route: PATH_AGENTS.contacts,
    icon: PersonOutlineIcon,
    external: false
  };
  const hotsheet_link = {
    name: 'Hotsheet',
    route: PATH_AGENTS.hotsheet,
    icon: BarChartIcon,
    external: false
  };

  useEffect(() => {
    if (displayedUserDetails) {
      const featureLinks: any = [];

      featureLinks.push(campaignsLink);
      if (displayedUserDetails?.feature_flag_contacts) {
        featureLinks.push(contacts_link);
      }
      if (displayedUserDetails?.feature_flag_hotsheet) {
        featureLinks.push(hotsheet_link);
      }

      setLinks([
        overview_link,
        ...featureLinks,
        tools_link,
        club_classic_link,
        help_link
      ]);
      setLoading(false);
    }
  }, [displayedUserDetails, ssoLink]);

  return (
    <Page title="Wizard | First Campaign">
      <RootStyle>
        <DashboardNavbar links={links} />
        <PaddedContainer maxWidth="xl">
          {loading ? (
            <LoadingWrapper>
              <LoadingScreen small />
            </LoadingWrapper>
          ) : (
            <WizardContext.Provider
              value={
                {
                  // firstCampaignIndex,
                  // setFirstCampaignIndex
                }
              }
            >
              <MainWizardContent>{children}</MainWizardContent>
            </WizardContext.Provider>
          )}
        </PaddedContainer>
      </RootStyle>
    </Page>
  );
}
