//  import { lazy} from 'react';
import { Redirect } from 'react-router';
import { PATH_OVERVIEW, PATH_FIRST_CAMPAIGN_WIZARD_EMPLOYEE } from './paths';
import ProfileLayout from '../layouts/staffboard/profile/index';
import StaffGuard from '../guards/StaffGuard';
import { lazyWithRetry } from './utils';
// ----------------------------------------------------------------------

const OneProfileRoutes = {
  path: PATH_OVERVIEW.root,
  guard: StaffGuard,
  layout: ProfileLayout,
  routes: [
    // ----------------------------------------------------------------------
    // PROFILE OVERVIEW
    {
      exact: true,
      path: PATH_OVERVIEW.overview,
      component: lazyWithRetry(() => import('../views/agent/Overview'))
    },
    {
      exact: true,
      path: PATH_OVERVIEW.contacts,
      component: lazyWithRetry(() => import('../views/agent/Contacts')) // ../views/agent/Contacts
    },
    {
      exact: true,
      path: PATH_OVERVIEW.analytics,
      component: lazyWithRetry(() => import('../components/profile/Analytics'))
    },
    {
      exact: true,
      path: PATH_OVERVIEW.content,
      component: lazyWithRetry(() => import('../components/profile/Content'))
    },
    {
      exact: true,
      path: PATH_OVERVIEW.createPost,
      component: lazyWithRetry(
        () => import('../components/profile/CreateContent')
      )
    },
    {
      exact: true,
      path: PATH_OVERVIEW.feed,
      component: lazyWithRetry(() => import('../components/profile/Feed'))
    },
    {
      exact: true,
      path: PATH_OVERVIEW.postHistory,
      component: lazyWithRetry(
        () => import('../components/profile/PostHistory')
      )
    },
    {
      exact: true,
      path: PATH_OVERVIEW.tasks,
      component: lazyWithRetry(() => import('../components/profile/Tasks'))
    },
    {
      exact: true,
      path: PATH_OVERVIEW.infusionsoft,
      component: lazyWithRetry(
        () => import('../components/profile/Infusionsoft')
      )
    },
    {
      exact: true,
      path: PATH_OVERVIEW.contact_sync,
      component: lazyWithRetry(() => import('../views/agent/ContactSync'))
    },
    {
      exact: true,
      path: PATH_OVERVIEW.hotsheet,
      component: lazyWithRetry(() => import('../views/agent/Hotsheet')) // ../views/agent/Hotsheet
    },
    {
      exact: true,
      path: PATH_OVERVIEW.allCampaigns,
      component: lazyWithRetry(
        () => import('../views/marketing-club/AllCampaigns')
      )
    },
    {
      exact: true,
      path: PATH_OVERVIEW.email_text_settings,
      component: lazyWithRetry(
        () => import('../components/profile/EmailTextSettings')
      )
    },
    {
      exact: true,
      path: PATH_OVERVIEW.account,
      component: lazyWithRetry(() => import('../views/agent/Account'))
    },
    {
      exact: true,
      path: PATH_OVERVIEW.posts,
      component: lazyWithRetry(() => import('../views/agent/Posts'))
    },
    {
      exact: true,
      path: PATH_OVERVIEW.yourCampaigns,
      component: lazyWithRetry(
        () => import('../views/marketing-club/YourCampaigns') // yourCampaigns: '/campaigns/your-campaigns',
      )
    },
    {
      exact: true,
      path: PATH_OVERVIEW.campaignPreview,
      component: lazyWithRetry(
        () => import('../views/marketing-club/CampaignPreview') // campaignPreview: '/:campaignId/campaign-preview',
      )
    },
    {
      exact: true,
      path: PATH_OVERVIEW.campaignDirector,
      component: lazyWithRetry(
        () => import('../views/marketing-club/CampaignDirector') // campaignDirector: '/campaign/:campaignId/launched',
      )
    },
    {
      exact: true,
      path: PATH_OVERVIEW.campaignSetup,
      component: lazyWithRetry(
        () => import('../views/marketing-club/CampaignSetup') // campaignSetup: '/campaign/:campaignId/setup'
      )
    },
    {
      exact: true,
      path: PATH_OVERVIEW.campaignDefault,
      component: lazyWithRetry(
        () => import('../views/marketing-club/CampaignDirector')
      )
    },
    {
      exact: true,
      path: PATH_FIRST_CAMPAIGN_WIZARD_EMPLOYEE.callToAction,
      component: lazyWithRetry(
        () => import('../components/wizard/first_campaign/CallToAction')
      )
    },
    {
      exact: true,
      path: PATH_FIRST_CAMPAIGN_WIZARD_EMPLOYEE.uploadContacts,
      component: lazyWithRetry(
        () => import('../components/wizard/first_campaign/UploadContacts')
      )
    },
    {
      exact: true,
      path: PATH_FIRST_CAMPAIGN_WIZARD_EMPLOYEE.connectSocials,
      component: lazyWithRetry(
        () => import('../components/wizard/first_campaign/ConnectSocials')
      )
    },

    {
      exact: true,
      path: PATH_FIRST_CAMPAIGN_WIZARD_EMPLOYEE.setupAgentIDSite,
      component: lazyWithRetry(
        () => import('../components/wizard/first_campaign/SetupAgentIDSite')
      )
    },

    {
      exact: true,
      path: PATH_FIRST_CAMPAIGN_WIZARD_EMPLOYEE.visitAgentIDSite,
      component: lazyWithRetry(
        () => import('../components/wizard/first_campaign/VisitAgentIDSite')
      )
    },
    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default OneProfileRoutes;
