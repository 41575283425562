import { IconButton } from '@material-ui/core';
import { IconButtonProps } from '@material-ui/core/IconButton';
import { experimentalStyled as styled } from '@material-ui/core/styles';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
  stretchedLink: boolean;
  disableRipple?: boolean;
}

export const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, stretchedLink, disableRipple, ...other } = props;
  return <IconButton disableRipple={disableRipple} {...other} />;
})(({ theme, expand, stretchedLink }) => ({
  position: stretchedLink ? 'static' : 'relative',

  '&:after': {
    display: stretchedLink ? 'block' : 'none',
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1000,
    pointerEvents: 'all',
    left: 0
  },

  '.chevron': {
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }
}));
