// ----------------------------------------------------------------------

// console.log('screen width xl: ', Math.ceil(screenWidth));
// console.log('screen width lg: ', Math.ceil(0.786 * screenWidth));
// console.log('screen width md: ', Math.ceil(0.517 * screenWidth));
// console.log('screen width sm: ', Math.ceil(0.344 * screenWidth));
// console.log('screen width xs: ', Math.ceil(0.786 * screenWidth));

// xs: 660,
// sm: 992,
// md: 1200,
// lg: 1510,
// xl: 1920

const breakpoints = {
  values: {
    xs:
      window.screen.availWidth > 1200
        ? Math.ceil(0.319 * window.screen.availWidth)
        : 660,
    sm:
      window.screen.availWidth > 1200
        ? Math.ceil(0.472 * window.screen.availWidth)
        : 1200,
    md:
      window.screen.availWidth > 1200
        ? Math.ceil(0.625 * window.screen.availWidth)
        : 1510,
    lg:
      window.screen.availWidth > 1200
        ? Math.ceil(0.802 * window.screen.availWidth)
        : 1800,
    xl: window.screen.availWidth > 1200 ? window.screen.availWidth : 1920
  }
};

export default breakpoints;
