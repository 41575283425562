import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Collapse
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Markdown from '../../Markdown';
import axiosInstance from '../../../utils/axios';
import { ExpandMore } from '../../ExpandMore';

type Props = {
  currentIndex: number | null;
  expand: boolean;
  setExpand: Function;
};

type FaqsProps = {
  answer: string;
  company: number;
  form_index: number;
  question: string;
  sort_index: number;
};

const Faqs = ({ currentIndex, expand, setExpand }: Props) => {
  const [faqsData, setfaqsData] = useState<FaqsProps[]>([]);
  const [openClosed, setOpenClosed] = useState<any[]>(
    new Array(20).fill(false)
  );

  useEffect(() => {
    const initCall = async () => {
      const response = await axiosInstance.get(`/faq-mc/${currentIndex}/`);
      setOpenClosed(new Array(response.data.results.length).fill(false));
      setfaqsData(response.data.results);
    };
    if (currentIndex !== null) {
      initCall();
    }
  }, [currentIndex]);

  const handleAccClick = (index: number) => {
    const openClosedTemp = [...openClosed];
    openClosedTemp[index] = !openClosed[index];
    setOpenClosed(openClosedTemp);
  };

  const faqs = faqsData.map((faq: FaqsProps, idx: number) => (
    <Accordion
      key={idx}
      expanded={typeof openClosed[idx] ? openClosed[idx] : false}
      onChange={(e) => handleAccClick(idx)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3bh-content"
        id="panel3bh-header"
      >
        <Typography fontWeight={700} style={{ wordWrap: 'break-word' }}>
          {faq.question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography style={{ wordWrap: 'break-word', lineHeight: '1rem' }}>
          <Markdown source={faq.answer} />
        </Typography>
      </AccordionDetails>
    </Accordion>
  ));

  return (
    <Card sx={{ mb: 3, height: 'min-content' }}>
      <CardHeader
        title="Need Some Help?"
        subheader="See FAQs"
        sx={{ pb: 2, position: 'relative' }}
        action={
          <ExpandMore
            expand={expand}
            stretchedLink
            onClick={() => setExpand(!expand)}
            aria-expanded={expand}
            aria-label="show more"
            disableRipple
          >
            <ExpandMoreIcon className="chevron" />
          </ExpandMore>
        }
      />
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <CardContent>{faqs}</CardContent>
      </Collapse>
    </Card>
  );
};

export default Faqs;
