import axios, { AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';
import * as Sentry from '@sentry/react';
// import { checkTokensMatch } from '../redux/slices/authJwt';
import { setAuthTokens } from '../redux/slices/authJwt';
import handleLogoutRedirect from './handleExpiredRefreshToken';
import { store } from '../redux/store';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_ROOT
});
// ----------------------------------------------------------------------

const isValidToken = async (token: string) => {
  if (!token) {
    return false;
  }
  try {
    const decoded = jwtDecode<{ exp: number }>(token);
    // Add buffer so that token does not expire
    // while request is made
    const currentTime = Date.now() / 1000 + 30;
    if (decoded.exp > currentTime) {
      return true;
    }
  } catch (err) {
    // console.trace('Invalid token, logging out');
    // handleLogoutRedirect();
    return false;
  }
  return false;
};

const setSession = async (
  accessToken: string | null,
  refreshToken?: string
) => {
  if (accessToken) {
    const { dispatch } = store;
    localStorage.setItem('accessToken', accessToken);
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken);
      await dispatch(setAuthTokens(accessToken, refreshToken));
    }
    const refreshes = localStorage.getItem('numOfRefreshes');
    if (!refreshes) {
      localStorage.setItem('numOfRefreshes', '0');
    }
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    delete axiosInstance.defaults.headers.common.Authorization;
    localStorage.removeItem('alreadyErrorAlerted');
    localStorage.removeItem('numOfRefreshes');
  }
};
// ----------------------------------------------------------------------

async function refreshToken() {
  try {
    const response = await axiosInstance.post('/api/token/refresh/', {
      refresh: localStorage.getItem('refreshToken')
    });
    if (response.data.access) {
      const { dispatch } = store;
      const accessToken = response.data.access;
      const refreshToken = response.data.refresh;
      setSession(accessToken, refreshToken);
      return accessToken;
    }
  } catch (err) {
    // console.trace(err);
    handleLogoutRedirect();
    // console.log(err);
  }
  return false;
}

// ----------------------------------------------------------------------

axiosInstance.interceptors.request.use(
  async (config) => {
    // Refresh token if needed
    // console.log('Request TO: ', config.url);
    const controller = new AbortController();
    if (
      config.url &&
      config.url !== '/api/token/refresh/' &&
      config.url !== '/api/token/' &&
      config.url.split('?')[0] !== 'dashboard/login'
    ) {
      const isValid = await isValidToken(localStorage.getItem('accessToken')!);
      if (!isValid) {
        const refreshIsValid = await isValidToken(
          localStorage.getItem('refreshToken')!
        );
        // Handle expired refresh token
        if (!refreshIsValid) {
          handleLogoutRedirect();
        }
        const newToken = await refreshToken();
        if (newToken) {
          config.headers.common.Authorization = `Bearer ${newToken}`;
        }
      }

      // Send the displayedUser pk in the request headers if the user is an employee.
      const state = store.getState();
      const isEmployee = state.authJwt?.user?.is_employee;
      const displayedUser = state.user?.displayedUserDetails;

      if (isEmployee && displayedUser) {
        config.url = `${config.url}${
          config.url.includes('?') ? '&' : '?'
        }customer_pk=${displayedUser?.id}`;
      }
    }
    return {
      ...config,
      signal: controller.signal
    };
  },
  (error) => {
    Sentry.setContext('Error Context', {
      Message: 'Error captured in nterceptors request.use',
      Error: error
    });
    Sentry.captureException(error);
    // The rejected promise is used to show info to user via snackbar
    return Promise.reject((error.response && error.response.data) || error);
  }
);

let isRefreshing = false;
let failedQueue: any = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const _axios = axiosInstance;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry &&
      error.config.url !== '/api/token/' &&
      error.config.url !== '/api/token/refresh/'
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token: any) => {
            setSession(token.access, token.refresh);
            _axios.defaults.headers.common.Authorization = `Bearer ${token.access}`;
            return _axios.request(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }
      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken =
        window.localStorage.getItem('refreshToken') || 'refresh123xyz';
      return new Promise((resolve, reject) => {
        axiosInstance
          .post('/api/token/refresh/', {
            refresh: refreshToken
          })
          .then((response) => {
            const accessToken = response.data.access;
            const newRefreshToken = response.data.refresh;
            _axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            setSession(accessToken, newRefreshToken);
            processQueue(null, response.data);
            resolve(_axios(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => (isRefreshing = false));
      });
    }
    if (shouldSendToSentry(error)) {
      Sentry.setContext('Error Context', {
        Message: 'Error captured in Axios interceptors response.use',
        Error: error
      });
      Sentry.captureException(error);
    }
    if (shouldLogout(error)) handleLogoutRedirect();
    return Promise.reject((error.response && error.response.data) || error);
  }
);

const shouldSendToSentry = (error: AxiosError) => {
  const state = store.getState();
  const { loginAttemptEmail } = state.authJwt;
  const { baseURL, url } = error.config || null;
  const statusCode =
    error.response && error.response.status ? error.response.status : null;
  const isLoginAttempt = url === '/api/token/';
  const isBackendRequest = baseURL === process.env.REACT_APP_SERVER_ROOT;

  // Do not send error to Sentry if it is an unauthorized request to backend
  if (
    isBackendRequest &&
    (statusCode === 401 ||
      (statusCode === 400 && !localStorage.getItem('refreshToken')))
  ) {
    const message = isLoginAttempt
      ? 'Failed login attempt'
      : 'Unauthorized request';
    Sentry.setTag('user_email', loginAttemptEmail);
    Sentry.captureMessage(message);
    return false;
  }

  // Do not send error to Sentry if bad file upload
  if (url === 'contacts/upload/' && statusCode === 400) {
    Sentry.captureMessage('There was a problem with the uploaded file');
    return false;
  }
  return true;
};

function shouldLogout(error: AxiosError) {
  const { baseURL } = error.config || null;
  const statusCode =
    error.response && error.response.status ? error.response.status : null;
  const isBackendRequest = baseURL === process.env.REACT_APP_SERVER_ROOT;
  // Logout if an unauthorized request to backend
  if (
    isBackendRequest &&
    (statusCode === 401 ||
      (statusCode === 400 && !localStorage.getItem('refreshToken')))
  ) {
    return false;
  }
  return false;
}

export default axiosInstance;
