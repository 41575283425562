import { ReactNode } from 'react';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
//
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import DashboardNavbar from '../../components/SubNav';
import Profiles from './profiles';
import Tasks from './tasks';
import { RootState } from '../../redux/store';
import { getStaffLinks } from '../../utils/getStaffLinks';

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  // display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
}));

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: theme.spacing(2), //  APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: theme.spacing(2) // APP_BAR_DESKTOP + 24,
  }
}));

type StaffLayoutProps = {
  children?: ReactNode;
};

export default function StaffLayout({ children }: StaffLayoutProps) {
  const { feature_flag_marketing } = useSelector(
    (state: RootState) => state.authJwt.user
  );
  const staffLinks = getStaffLinks(feature_flag_marketing);

  const loc = useLocation();
  let App = () => <div>{children}</div>;
  switch (loc.pathname.split('/')[2]) {
    case 'all-agents':
      App = () => <Profiles>{children}</Profiles>;
      break;
    case 'new-amc-agents':
      App = () => <Profiles>{children}</Profiles>;
      break;
    case 'new-mc-agents':
      App = () => <Profiles>{children}</Profiles>;
      break;
    case 'paused-accounts':
      App = () => <Profiles>{children}</Profiles>;
      break;
    case 'tasks':
      App = () => <Tasks>{children}</Tasks>;
      break;
    case 'coming-soon':
      App = () => <div>{children}</div>;
      break;
  }
  return (
    <RootStyle>
      <DashboardNavbar links={staffLinks} />
      <MainStyle>
        <App />
      </MainStyle>
    </RootStyle>
  );
}
