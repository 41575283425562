import { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
//
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Box, Container } from '@material-ui/core';
import useWindowSize from '../../../hooks/useWindowSize';
import breakpoints from '../../../theme/breakpoints';
import { PATH_OVERVIEW } from '../../../routes/paths';
import axiosInstance from '../../../utils/axios';
import DashboardNavbar from '../../../components/SubNav';
import EmployeeCustomerNav from './EmployeeCustomerNav';
import ProfileContext from '../../../components/profile/ProfileContext';
import { RootState } from '../../../redux/store';
import { getStaffLinks } from '../../../utils/getStaffLinks';
// import useAuth from '../../../hooks/useAuth';
import { getCustomerProfileForEmployee } from '../../../redux/slices/displayedUser';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: '100%',
  paddingBottom: theme.spacing(10)
}));

const MainStyle = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  minHeight: '100%',
  marginTop: theme.spacing(2)
}));

const OverviewGrid = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}));

// ----------------------------------------------------------------------

type StaffLayoutProps = {
  children?: ReactNode;
};
type User = any;

export default function ProfilesLayout({ children }: StaffLayoutProps) {
  const dispatch = useDispatch();
  const { userId = '' } = useParams<{ userId?: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [links, setLinks] = useState<any>([]);
  // const { displayedUserDetails } = useAuth();
  const { displayedUserDetails } = useSelector(
    (state: RootState) => state.user
  );
  const [userSecondary, setUserSecondary] = useState<User>({});
  const history = useHistory();
  const size = useWindowSize();
  const { feature_flag_marketing } = useSelector(
    (state: RootState) => state.authJwt.user
  );
  const staffLinks = getStaffLinks(feature_flag_marketing);
  const gridColNav =
    size.width < breakpoints.values.sm
      ? { gridColumn: 'span 1', overflow: 'scroll hidden' }
      : size.width < breakpoints.values.md
      ? { gridColumn: 'span 2', overflow: 'scroll hidden' }
      : size.width < breakpoints.values.lg
      ? { gridColumn: 'span 3' }
      : { gridColumn: 'span 4' };

  if (
    !displayedUserDetails ||
    userId !== displayedUserDetails?.id?.toString()
  ) {
    dispatch(getCustomerProfileForEmployee(userId));
  }

  useEffect(() => {
    try {
      const linksList = [
        {
          name: 'Home',
          route: PATH_OVERVIEW.overview.replace(':userId', userId),
          external: false
        },
        {
          name: 'Campaigns',
          route: PATH_OVERVIEW.allCampaigns.replace(':userId', userId),
          external: false
        },
        {
          name: 'Contacts',
          route: PATH_OVERVIEW.contacts.replace(':userId', userId),
          external: false
        },
        {
          name: 'Hotsheet',
          route: PATH_OVERVIEW.hotsheet.replace(':userId', userId),
          external: false
        },
        /*
        If we combine to Leads again uncomment this
        {
          name: 'Leads',
          route: displayedUserDetails?.feature_flag_hotsheet
            ? PATH_OVERVIEW.hotsheet.replace(':userId', userId)
            : displayedUserDetails?.feature_flag_contacts
            ? PATH_OVERVIEW.contacts.replace(':userId', userId)
            : displayedUserDetails?.secondary_information
                ?.hotsheet_most_recent_report
            ? displayedUserDetails?.secondary_information
                ?.hotsheet_most_recent_report
            : '',
          external: false
        },
        */
        {
          name: 'Account',
          route: PATH_OVERVIEW.account.replace(':userId', userId),
          external: false
        },
        {
          name: 'Infusionsoft',
          route: PATH_OVERVIEW.infusionsoft.replace(':userId', userId),
          external: false
        }
      ];
      if (
        displayedUserDetails?.secondary_information?.hotsheet_most_recent_report
      ) {
        linksList.push({
          name: 'AC Hotsheet',
          route:
            displayedUserDetails?.secondary_information
              ?.hotsheet_most_recent_report,
          external: true
        });
      }
      setLinks(linksList);
      setLoading(false);
    } catch (err) {
      history.push('/404');
    }
  }, [displayedUserDetails]);

  useEffect(() => {
    const getCustomerSecondaryInformation = async () => {
      const response = await axiosInstance.get(
        `/user/customers/profile/${userId}/`
      );
      setUserSecondary(response.data.secondary_information);
    };

    getCustomerSecondaryInformation();
  }, [userId]);

  if (loading) {
    return (
      <RootStyle>
        <DashboardNavbar links={staffLinks} />
      </RootStyle>
    );
  }

  return (
    <RootStyle>
      <DashboardNavbar links={staffLinks} />
      <Container maxWidth="xl">
        <OverviewGrid>
          <Box sx={gridColNav}>
            <EmployeeCustomerNav
              links={links}
              needScroll={size.width < breakpoints.values.md}
            />
          </Box>
        </OverviewGrid>
        <ProfileContext.Provider
          value={{
            userSecondary,
            loading
          }}
        >
          <MainStyle>{children}</MainStyle>
        </ProfileContext.Provider>
      </Container>
    </RootStyle>
  );
}
