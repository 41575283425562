//  import { lazyWithRetry } from 'react';
import { Redirect } from 'react-router';
import { PATH_MARKETING } from './paths';
import DashboardLayout from '../layouts/staffboard/index';
import StaffGuard from '../guards/StaffGuard';
import { lazyWithRetry } from './utils';

const MarketingRoutes = {
  path: PATH_MARKETING.root,
  guard: StaffGuard,
  layout: DashboardLayout,
  routes: [
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_MARKETING.campaigns,
      component: lazyWithRetry(
        () => import('../components/marketing_content/index')
      )
    },
    {
      exact: true,
      path: PATH_MARKETING.marketingTemplates,
      component: lazyWithRetry(
        () => import('../components/marketing_content/index')
      )
    },
    {
      exact: true,
      path: PATH_MARKETING.postRecords,
      component: lazyWithRetry(
        () => import('../components/marketing_content/index')
      )
    },
    {
      exact: true,
      path: PATH_MARKETING.createNewCampaign,
      component: lazyWithRetry(
        () => import('../components/marketing_content/index')
      )
    },
    {
      exact: true,
      path: PATH_MARKETING.campaignTemplate,
      component: lazyWithRetry(
        () => import('../components/marketing_content/index')
      )
    },
    {
      exact: true,
      path: PATH_MARKETING.contentTemplate,
      component: lazyWithRetry(
        () => import('../components/marketing_content/index')
      )
    },
    {
      exact: true,
      path: PATH_MARKETING.createNewCampaignContent,
      component: lazyWithRetry(
        () => import('../components/marketing_content/index')
      )
    },
    {
      exact: true,
      path: PATH_MARKETING.postRecordsBulk,
      component: lazyWithRetry(
        () => import('../components/marketing_content/index')
      )
    },
    {
      exact: true,
      path: PATH_MARKETING.campaignEdit,
      component: lazyWithRetry(
        () => import('../components/marketing_content/index')
      )
    },
    {
      exact: true,
      path: PATH_MARKETING.campaignClone,
      component: lazyWithRetry(
        () => import('../components/marketing_content/index')
      )
    },
    {
      exact: true,
      path: PATH_MARKETING.createNewTemplateContent,
      component: lazyWithRetry(
        () => import('../components/marketing_content/index')
      )
    },
    {
      exact: true,
      path: PATH_MARKETING.postNow,
      component: lazyWithRetry(
        () => import('../components/marketing_content/index')
      )
    },
    {
      exact: true,
      path: PATH_MARKETING.postNowCampaign,
      component: lazyWithRetry(
        () => import('../components/marketing_content/index')
      )
    },
    {
      exact: true,
      path: PATH_MARKETING.contentEdit,
      component: lazyWithRetry(
        () => import('../components/marketing_content/index')
      )
    },
    {
      exact: true,
      path: PATH_MARKETING.contentClone,
      component: lazyWithRetry(
        () => import('../components/marketing_content/index')
      )
    },
    {
      exact: true,
      path: PATH_MARKETING.campaignSetupBatch,
      component: lazyWithRetry(
        () => import('../views/marketing-club/CampaignSetupBatch')
      )
    },
    {
      exact: true,
      path: PATH_MARKETING.campaignLaunchedBatch,
      component: lazyWithRetry(
        () => import('../components/marketing_content/PostRecordsBatch')
      )
    },
    {
      exact: true,
      path: PATH_MARKETING.campaignPostRecordBatchSingle,
      component: lazyWithRetry(
        () => import('../components/marketing_content/PostRecordBatchSingle')
      )
    },
    {
      exact: true,
      path: PATH_MARKETING.campaignRecords,
      component: lazyWithRetry(
        () => import('../components/marketing_content/index')
      )
    },

    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default MarketingRoutes;
