import { ReactNode, useCallback, useEffect, useState, useRef } from 'react';
// material
import {
  experimentalStyled as styled,
  useTheme
} from '@material-ui/core/styles';
import {
  Box,
  Card,
  CardHeader,
  CardActions,
  Collapse,
  Container
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// greyl;and
// sentry
import * as Sentry from '@sentry/react';

import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { PATH_MARKETING_CLUB_WIZARD, PATH_HOME } from '../../routes/paths';
import DashboardNavbar from '../../components/SubNav';
import useAuth from '../../hooks/useAuth';

import WizardContext from '../../components/wizard/WizardContext';
import Map from '../../components/wizard/Map';
// import FaqsMC from '../../components/wizard/marketing_club/FaqsMC';
import axiosInstance from '../../utils/axios';
import LoadingScreen from '../../components/LoadingScreen';
import Page from '../../components/Page';
import Buttons from '../../components/wizard/Buttons';
import { ExpandMore } from '../../components/ExpandMore';
import { getSelfCustomerProfile } from '../../redux/slices/displayedUser';
import getSSOLink from '../../utils/sso';

const OverviewGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',
  gap: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(4, 1fr)'
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(3, 1fr)'
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)'
  },
  [theme.breakpoints.down('xs')]: {
    gridTemplateColumns: '1fr'
  }
}));
const HeaderWrapper = styled(Box)(({ theme }) => ({
  gridColumn: 'span 1',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: theme.spacing(3)
}));

const MainWrapper = styled(Box)(({ theme }) => ({
  gridColumn: '2 / span 3',
  //  display: 'grid',
  gap: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    gridColumn: '1 / -1'
  }
}));

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: '100%',
  overflow: 'hidden',
  width: '100%',
  paddingBottom: theme.spacing(6),
  '--xPad': theme.spacing(4),
  '--yPad': theme.spacing(2),

  [theme.breakpoints.down('sm')]: {
    '--xPad': theme.spacing(3),
    '--yPad': theme.spacing(2)
  }
}));

const ButtonSection = styled(CardActions)(({ theme }) => ({
  '--btnPadTop': theme.spacing(3),
  justifyContent: 'flex-end',
  paddingLeft: 'var(--xPad)',
  paddingRight: 'var(--xPad)',
  paddingTop: 'var(--btnPadTop)',
  // Bottom padding should equal side padding
  paddingBottom: 'var(--xPad)',
  [theme.breakpoints.down('sm')]: {
    '--btnPadTop': theme.spacing(3)
  }
}));

const MainWizardContent = styled('div')(() => ({
  flexGrow: 1,
  overflow: 'hidden',
  minHeight: '100%'
}));

const CardRoot = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

const LoadingWrapper = styled(Box)(() => ({
  width: '100%',
  height: '20rem',
  '& .loading-spinner': {
    minHeight: '100vh',
    transform: 'translateY(-5rem)'
  }
}));

const PaddedContainer = styled(Container)(() => ({
  marginTop: '20px'
}));

export const WizCardContent = styled(Box)(() => ({
  padding: 'var(--yPad) var(--xPad)'
}));

type WizardLayoutProps = {
  children?: ReactNode;
};

const PAGES_ORDER = [
  {
    name: 'Get Started',
    path: PATH_MARKETING_CLUB_WIZARD.welcome
  },
  {
    name: 'Setup AgentID',
    path: PATH_MARKETING_CLUB_WIZARD.setupAgentID
  },
  {
    name: "Let's Go",
    path: PATH_MARKETING_CLUB_WIZARD.letsGo
  }
];

export default function WizardLayout({ children }: WizardLayoutProps) {
  const {
    user: { email },
    displayedUserDetails
  } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSM = useMediaQuery(theme.breakpoints.down('xs'));
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const [expandTimeline, setExpandTimeline] = useState<boolean>(true);
  const [expandFAQ, setExpandFAQ] = useState<boolean>(true);
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number | null>(0);
  // const [agentIDIndex, setAgentIDIndex] = useState<number>(0);
  const [latestIndex, setLatestIndex] = useState<number | null>(null);
  const [infusionsoft_id, setInfusionsoft_id] = useState('');
  const [nextButton, setNextButton] = useState<boolean>(false);
  const [nextDataToSave, setNextDataToSave] = useState<any>();
  const [validationFromBackend, setValidationFromBackend] = useState<boolean>(
    true
  );
  const firstRenderDisplayedUser = useRef(true);

  const [ssoLink, setSSOLink] = useState('');

  const [formErrors, setFormErrors] = useState<Object>({});
  const [agentIDCreated, setAgentIDCreated] = useState<boolean>(false);
  const [createAgentID, setCreateAgentID] = useState<boolean>(false);

  const [disableNextFromErrors, setDisableNextFromErrors] = useState<boolean>(
    false
  );
  const currentRoute = location.pathname;
  const agentIDRoute = PATH_MARKETING_CLUB_WIZARD.setupAgentID;
  const hideButtons = currentRoute === agentIDRoute && createAgentID;

  Sentry.setTag('layout', 'wizardboard');

  useEffect(() => {
    if (displayedUserDetails) {
      getSSOLink(
        displayedUserDetails.infusionsoft_id,
        email,
        'dashboard'
      ).then((link) => setSSOLink(link));
    }
  }, [displayedUserDetails]);

  useEffect(() => {
    if (!displayedUserDetails && firstRenderDisplayedUser.current) {
      firstRenderDisplayedUser.current = false;
      dispatch(getSelfCustomerProfile());
    }
  }, []);

  useEffect(() => {
    if (displayedUserDetails) {
      setInfusionsoft_id(displayedUserDetails.infusionsoft_id);
    }
  }, [displayedUserDetails]);

  const toggleExpandTimeline = () => {
    setExpandTimeline(!expandTimeline);
  };

  useEffect(() => {
    history.listen(async (location, action) => {
      if (action === 'POP') {
        const fetchIdx = PAGES_ORDER.findIndex(
          (pageOrder) => location.pathname === pageOrder.path
        );
        if (fetchIdx >= 0 && fetchIdx <= 2) {
          setLoading(true);
          const response = await axiosInstance.get(
            `/mc-intake-form/${fetchIdx}/`
          );
          setCurrentIndex(fetchIdx);
          setData(response.data);
          setLoading(false);
        } else {
          window.location.reload();
        }
      }
    });
  }, []);

  const onNext = useCallback(async () => {
    setLoading(true);
    setCreateAgentID(false);

    try {
      // Only continue with backend validation if the frontend validation is passed
      if (currentIndex && currentIndex > 0) {
        const updatedErrors: { [key: string]: any } = { ...formErrors };
        let isPageValid = true;

        Object.keys(updatedErrors).forEach((key: string) => {
          const field = updatedErrors[key];
          if (field && !field.valid) {
            updatedErrors[key] = {
              ...field,
              valid: false,
              error: 'This field is required',
              required: field.required
            };
            isPageValid = false;
          }
        });
        setFormErrors(updatedErrors);
        if (!isPageValid) {
          setLoading(false);
          return;
        }
      }

      // This is annoying and kinda confusing.  But the agentID setup
      // needs to have it's own subindex.  There will be multiple times that
      // the customer hits Next and moves to a differnt center card, but
      // the main index stays on Setup AgentID.  So we conditionally have to
      // advance this subindex and potentially make API calls.

      const shouldSkip = currentIndex === 1 && !createAgentID;

      if (currentIndex === latestIndex && !shouldSkip) {
        const response = await axiosInstance.post(`/mc-intake-form/`, {
          is_done: true,
          current_index: currentIndex
        });
        if (currentIndex === 0) {
          process.env.REACT_APP_FRONTEND_DOMAIN
            ? window.location.replace(process.env.REACT_APP_FRONTEND_DOMAIN)
            : window.location.reload();
        } else {
          const { index } = response.data.form_info;
          setCurrentIndex(index);
          setLatestIndex(index);
          setData(response.data);
          history.push(PAGES_ORDER[index].path);
        }
      } else {
        if (currentIndex === 1) {
          setLatestIndex(currentIndex + 1);
          // If we make it into this if block then the businessAndBrokerage information & AgentID setup
          // was skipped and we need to increment the latest index in order to complete the form on the last page.
        }

        let index = currentIndex || 0;
        index = currentIndex! + 1;

        setCurrentIndex(index);
        const response = await axiosInstance.get(`/mc-intake-form/${index}/`);
        setData(response.data);
        history.push(PAGES_ORDER[currentIndex! + 1].path);
      }
      if (currentIndex != 2) {
        setLoading(false);
      }
      setNextButton(false);
    } catch (error: any) {
      console.log(error);
    }
  }, [history, currentIndex, latestIndex, nextDataToSave]);

  const onBack = useCallback(async () => {
    setLoading(true);
    setCreateAgentID(false);
    // This is annoying and kinda confusing.  But the agentID setup
    // needs to have it's own subindex.  There will be multiple times that
    // the customer hits Next and moves to a differnt center card, but
    // the main index stays on Setup AgentID.  So we conditionally have to
    // advance this subindex and potentially make API calls.
    let index: number = currentIndex! || 0;
    index = currentIndex! - 1;

    if (index >= 0 && index <= 2) {
      const response = await axiosInstance.get(`/mc-intake-form/${index}/`);
      setCurrentIndex(index);
      setData(response.data);
      history.push(PAGES_ORDER[index].path);
      setLoading(false);
      setNextButton(false);
    } else {
      window.location.reload();
    }
  }, [history, currentIndex, latestIndex]);

  const saveChange = useCallback(
    async (data: any) => {
      setSaving(true);
      try {
        const response = await axiosInstance.post(
          `/mc-intake-form/${currentIndex}/`,
          data
        );
        setData(response.data);
        if (currentIndex === 2) {
          process.env.REACT_APP_FRONTEND_DOMAIN
            ? window.location.replace(process.env.REACT_APP_FRONTEND_DOMAIN)
            : window.location.reload();
        }
        setSaving(false);
        return response.data.form_info.current_form_complete;
      } catch (err: any) {
        setSaving(false);
        return false;
      }
    },
    [history, currentIndex]
  );

  const onFetch = async (url: string, idx: number) => {
    setLoading(true);
    if (idx >= 0 && idx <= 2) {
      const response = await axiosInstance.get(`/mc-intake-form/${idx}/`);
      setCurrentIndex(idx);
      setData(response.data);
      history.push(url);
      setLoading(false);
    } else {
      window.location.reload();
    }
  };

  const links = [
    {
      name: 'Welcome',
      route: PATH_HOME.agent,
      icon: HomeIcon,
      routesToAssignToParent: [
        PATH_MARKETING_CLUB_WIZARD.setupAgentID,
        PATH_MARKETING_CLUB_WIZARD.setupContactInfo,
        PATH_MARKETING_CLUB_WIZARD.businessAndBrokerage,
        PATH_MARKETING_CLUB_WIZARD.helpUsGetToKnowYou,
        PATH_MARKETING_CLUB_WIZARD.letsGo
      ]
    },
    {
      name: 'Club Classic',
      route: ssoLink,
      icon: DashboardIcon,
      external: true
    }
  ];

  useEffect(() => {
    const initCall = async () => {
      // Receiving the latest page data
      // TODO: handle error with try/catch
      // const response = currentIndex
      //   ? await axiosInstance.get(`/intake-form/${currentIndex}/`)
      //   : await axiosInstance.get('/intake-form/');
      const response = await axiosInstance.get('/mc-intake-form/');
      setData(response.data);
      setCurrentIndex(response.data.form_info.index);
      setLatestIndex(response.data.form_info.index);
      history.push(PAGES_ORDER[response.data.form_info.index].path);
    };
    setLoading(true);
    initCall();
    setLoading(false);
  }, []);

  const enableNext = () => {
    setNextButton(true);
  };

  const disableNext = () => {
    setNextButton(false);
  };

  const StepsCard = (
    <Card sx={{ height: 'min-content' }}>
      <CardHeader
        sx={{ pb: 2, position: 'relative' }}
        title="Complete Your Profile"
        subheader={`${latestIndex || 0} out of 3 completed`}
        action={
          <ExpandMore
            expand={expandTimeline}
            stretchedLink
            onClick={toggleExpandTimeline}
            aria-expanded={expandTimeline}
            aria-label="show more"
            disableRipple
          >
            <ExpandMoreIcon className="chevron" />
          </ExpandMore>
        }
      />
      <Collapse in={expandTimeline} timeout="auto" unmountOnExit>
        <Map
          onFetch={onFetch}
          latestIndex={latestIndex}
          pagesOrder={PAGES_ORDER}
          currentIndex={currentIndex}
        />
      </Collapse>
    </Card>
  );
  return (
    <Page title="Wizard | Marketing Club">
      <RootStyle>
        <DashboardNavbar links={links} />
        <PaddedContainer maxWidth="xl">
          <OverviewGrid>
            {/* {!isMobile ? ( */}
            {/*   <HeaderWrapper> */}
            {/*     {StepsCard} */}
            {/*     {downMd && !isMobile ? ( */}
            {/*       <FaqsMC */}
            {/*         currentIndex={currentIndex} */}
            {/*         expand={expandFAQ} */}
            {/*         setExpand={setExpandFAQ} */}
            {/*       /> */}
            {/*     ) : ( */}
            {/*       '' */}
            {/*     )} */}
            {/*   </HeaderWrapper> */}
            {/* ) : ( */}
            {/*   '' */}
            {/* )} */}

            <MainWrapper>
              {/* {isMobile ? ( */}
              {/*   isXSM ? ( */}
              {/*     <Box */}
              {/*       sx={{ */}
              {/*         display: 'flex', */}
              {/*         flexDirection: 'column', */}
              {/*         gap: theme.spacing(3), */}
              {/*         paddingBottom: theme.spacing(2) */}
              {/*       }} */}
              {/*     > */}
              {/*       {StepsCard} */}
              {/*       <FaqsMC */}
              {/*         currentIndex={currentIndex} */}
              {/*         expand={expandFAQ} */}
              {/*         setExpand={setExpandFAQ} */}
              {/*       /> */}
              {/*     </Box> */}
              {/*   ) : ( */}
              {/*     <Box */}
              {/*       sx={{ */}
              {/*         display: 'flex', */}
              {/*         flexDirection: 'row', */}
              {/*         gap: theme.spacing(2), */}
              {/*         paddingBottom: theme.spacing(2) */}
              {/*       }} */}
              {/*     > */}
              {/*       <Box sx={{ width: '100%' }}>{StepsCard}</Box> */}
              {/*       <Box sx={{ width: '100%' }}> */}
              {/*         <FaqsMC */}
              {/*           currentIndex={currentIndex} */}
              {/*           expand={expandFAQ} */}
              {/*           setExpand={setExpandFAQ} */}
              {/*         /> */}
              {/*       </Box> */}
              {/*     </Box> */}
              {/*   ) */}
              {/* ) : ( */}
              {/*   '' */}
              {/* )} */}
              <CardRoot>
                {loading || !data ? (
                  <LoadingWrapper>
                    <LoadingScreen small />
                  </LoadingWrapper>
                ) : (
                  <WizardContext.Provider
                    value={{
                      completePage: onNext,
                      data,
                      saveChange,
                      saving,
                      enableNext,
                      disableNext,
                      setNextDataToSave,
                      nextDataToSave,
                      validationFromBackend,
                      setDisableNextFromErrors,
                      setFormErrors,
                      formErrors,
                      agentIDCreated,
                      setAgentIDCreated,
                      onNext,
                      onBack,
                      createAgentID,
                      setCreateAgentID
                    }}
                  >
                    <MainWizardContent>{children}</MainWizardContent>
                    <ButtonSection sx={{ display: hideButtons ? 'none' : '' }}>
                      <Buttons
                        onBack={onBack}
                        onNext={onNext}
                        noBack={currentIndex === 0}
                        disabled={disableNextFromErrors}
                        onFetch={onFetch}
                        currentIndex={currentIndex}
                      />
                    </ButtonSection>
                  </WizardContext.Provider>
                )}
              </CardRoot>
            </MainWrapper>
            {/* {!downMd ? ( */}
            {/*   <HeaderWrapper> */}
            {/*     <FaqsMC */}
            {/*       currentIndex={currentIndex} */}
            {/*       expand={expandFAQ} */}
            {/*       setExpand={setExpandFAQ} */}
            {/*     /> */}
            {/*   </HeaderWrapper> */}
            {/* ) : ( */}
            {/*   '' */}
            {/* )} */}
          </OverviewGrid>
        </PaddedContainer>
      </RootStyle>
    </Page>
  );
}
