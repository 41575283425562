import { datadogRum } from '@datadog/browser-rum';
import { createBrowserHistory } from 'history';
import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';
// utils
import axios from '../../utils/axios';
// @types
import { User } from '../../@types/account';
import {
  setSession,
  isValidToken,
  refreshToken as newRefreshToken
} from './utils';
import handleLogoutRedirect from '../../utils/handleExpiredRefreshToken';
import setSentryTags from '../../utils/setSentryTags';
import { isEmployee } from '../../utils/getUserType';
import { PATH_AUTH } from '../../routes/paths';

// ------------------------------------------------------------------------------
// This Redux slice is exclusively for the user authentication and related actions
// For any other customer

type AuthJWTState = {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: User;
  redirect: string;
  openSettingsModal: boolean;
  urlParams: any;
  accessToken: string;
  refreshToken: string;
  loginAttemptEmail: string;
};

const unAuthUser: User = {
  first_name: '',
  last_name: '',
  email: '',
  is_staff: false,
  is_employee: false,
  is_customer: false,
  feature_flag_marketing: false
};

const initialState: AuthJWTState = {
  isLoading: true,
  isAuthenticated: false,
  user: unAuthUser,
  redirect: '',
  openSettingsModal: false,
  urlParams: {},
  accessToken: '',
  refreshToken: '',
  loginAttemptEmail: ''
};

const slice = createSlice({
  name: 'authJwt',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getInitialize(state, action) {
      setSentryTags(action.payload);
      state.user = action.payload.user;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.isLoading = false;
    },

    // LOGIN
    loginSuccess(state, action) {
      setSentryTags(action.payload);
      state.user = action.payload.user;
      state.isAuthenticated = true;
    },

    // REGISTER
    registerSuccess(state, action) {
      setSentryTags(action.payload);
      state.user = action.payload.user;
      state.isAuthenticated = true;
    },

    // LOGOUT
    logoutSuccess(state) {
      setSentryTags({
        user: false
      });
      state.isAuthenticated = false;
      state.user = unAuthUser;
    },

    setSettingsModalSuccess(state, action) {
      state.openSettingsModal = action.payload;
    },
    setRedirectSuccess(state, action) {
      state.redirect = action.payload.redirect;
    },
    setURLParamsSuccess(state, action) {
      state.urlParams = action.payload.urlParams;
    },
    setAuthTokensSuccess(state, action) {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    setLoginAttemptEmail(state, action) {
      state.loginAttemptEmail = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export const { setLoginAttemptEmail } = slice.actions;

// ----------------------------------------------------------------------
export function getUser() {
  return async () => {
    const { dispatch } = store;
    if (!axios.defaults.headers.common.Authorization) {
      setSession(
        localStorage.getItem('accessToken'),
        localStorage.getItem('refreshToken')!
      );
    }

    try {
      const response = await axios.get('/user/me/');
      const user = response.data;
      // Set DD user context
      datadogRum.setUser({
        id: user.id,
        email: user.email,
        name: user.name,
        subscription_type: user.subscription_type || 'NONE'
      });
      dispatch(slice.actions.loginSuccess({ user }));
      return response;
    } catch (err) {
      return 'error';
    }
  };
}
// ----------------------------------------------------------------------

export function login({
  email,
  password
}: {
  email: string;
  password: string;
}) {
  return async () => {
    try {
      const response = await axios.post('/api/token/', {
        email,
        password
      });
      const accessToken = response.data.access;
      const refreshToken = response.data.refresh;
      setSession(accessToken, refreshToken);
    } catch (err) {
      console.log('error in login', err);
    }
  };
}

// ----------------------------------------------------------------------

export function register({
  email,
  password,
  firstName,
  lastName
}: {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}) {
  return async () => {
    const { dispatch } = store;

    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch(slice.actions.registerSuccess({ user }));
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async () => {
    if (!isEmployee()) {
      // Clear DD user context
      datadogRum.clearUser();
      handleLogoutRedirect();
      setSession(null);
    } else {
      const { dispatch } = store;
      setSession(null);
      dispatch(slice.actions.logoutSuccess());
    }
  };
}

// ----------------------------------------------------------------------

export function setSettingsModal({
  settingsModal
}: {
  settingsModal: boolean;
}) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.setSettingsModalSuccess({ settingsModal }));
  };
}

// ----------------------------------------------------------------------

export function setRedirect({ redirect }: { redirect: string }) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.setRedirectSuccess({ redirect }));
  };
}

// ----------------------------------------------------------------------

export function setAuthTokens(accessToken: string, refreshToken: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.setAuthTokensSuccess({ accessToken, refreshToken }));
  };
}

// ----------------------------------------------------------------------

export function checkTokensMatch(accessToken: string, refreshToken: string) {
  return async () => {
    const { getState } = store;
    const axiosAccessToken = getState().authJwt.accessToken;
    const axiosRefreshToken = getState().authJwt.refreshToken;
    const location = window.location.pathname;
    const isSSO = location.includes(PATH_AUTH.sso);

    try {
      if (
        (accessToken != axiosAccessToken ||
          refreshToken != axiosRefreshToken) &&
        getState().authJwt.isAuthenticated
      ) {
        // Refreshing the tokens here will reset the user in the backend
        if (!isSSO) {
          await newRefreshToken(refreshToken);
          // Then check if the backend user and user sotred in redux match
          // If they do not then navigate to the root page
          const userResponse = await axios.get('/user/me/');
          const user = userResponse.data;
          const reduxUser = getState().authJwt.user;
          if (user.email !== reduxUser.email) {
            window.location.replace(
              process.env.REACT_APP_FRONTEND_DOMAIN
                ? process.env.REACT_APP_FRONTEND_DOMAIN
                : 'https://hub.thepaperlessagent.com/'
            );
          }
        }
        // Then check if the backend user and user sotred in redux match
        // If they do not then navigate to the root page
      }
    } catch (err) {
      console.log(`Error, redirecting the user...\n\n${err}`);
    }
  };
}

// ----------------------------------------------------------------------

export function getInitialize() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());
    try {
      // If login from SSO use tokens in query params
      // else use local storage
      const location = window.location.pathname;
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams) {
        dispatch(slice.actions.setURLParamsSuccess({ urlParams }));
      }

      const isSSO = location.includes(PATH_AUTH.sso);
      if (
        urlParams.get('openSettingsModal') &&
        urlParams.get('openSettingsModal') == 'true'
      ) {
        dispatch(
          slice.actions.setSettingsModalSuccess({ openSettingsModal: true })
        );
      }
      const accessToken = isSSO
        ? urlParams.get('access_token')
        : window.localStorage.getItem('accessToken');
      const refreshToken = isSSO
        ? urlParams.get('refresh_token')
        : window.localStorage.getItem('refreshToken');

      let redirect: any = '';
      if (isSSO && urlParams.get('redirect')) {
        redirect = urlParams.get('redirect');
      }
      // Check if token is valid
      const accessTokenIsValid = await isValidToken(
        accessToken!,
        refreshToken!
      );

      // If token is valid set session and dispatch authenticated
      // else set session null and dispatch unauthenticated
      if (
        accessToken &&
        accessTokenIsValid &&
        urlParams.get('sso') !== 'true'
      ) {
        if (!axios.defaults.headers.common.Authorization) {
          setSession(accessToken, refreshToken!);
        }

        const response = await axios.get('/user/me/');
        const user = response.data;
        // Set DD user context
        datadogRum.setUser({
          id: user.id,
          email: user.email,
          name: user.name,
          subscription_type: user.subscription_type || 'NONE'
        });
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user
          })
        );
        createBrowserHistory().push(location);
      } else if (urlParams.get('sso') == 'true') {
        try {
          const key = urlParams.get('key');
          const email = urlParams.get('email');
          const redirect = urlParams.get('redirect');
          dispatch(slice.actions.setRedirectSuccess({ redirect }));
          const responseSSO = await axios.get(
            `dashboard/login?sso=true&key=${key}&email=${email}&redirect=${redirect}`
          );
          setSession(
            responseSSO.data.access_token,
            responseSSO.data.refresh_token
          );

          const responseUser = await axios.get('/user/me/');
          const user = responseUser.data;
          dispatch(
            slice.actions.getInitialize({
              isAuthenticated: true,
              user
            })
          );
          if (urlParams.get('openSettingsModal') == 'true') {
            dispatch(
              slice.actions.setSettingsModalSuccess({ openSettingsModal: true })
            );
          }
          createBrowserHistory().push(location);
        } catch (err) {
          dispatch(
            slice.actions.getInitialize({
              isAuthenticated: false,
              user: unAuthUser
            })
          );
          setSession(null);
        }
      } else {
        // If the user has a token and they are not an employee
        // go to dashboard
        // const userType = isEmployee() ? 'employee' : 'customer';
        // accessToken
        if (localStorage.getItem('accessToken') && !isEmployee())
          handleLogoutRedirect();
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: unAuthUser
          })
        );
        setSession(null);
      }
    } catch (error) {
      setSession(null);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null
        })
      );
    }
  };
}
